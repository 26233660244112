export default async function getSysParameters({ context, nextMiddleware }){
    console.log('getSysParameters')
    try {
        await context.store.dispatch('getSysParameters')
        await context.store.dispatch('getSystemSetting')
        await context.store.dispatch('getEnvLkk')
    }
    catch(e){
        console.error(e)
        return context.next(false)
    }
    return nextMiddleware()
}
