import {Module} from "vuex";
export interface ILoaderState{
    show?: boolean;
    message?: string;
    delay?: number;
    manual?: boolean;
};
export default <Module<ILoaderState, any>>{
    state:{
            show: false,
            message: '',
            delay: 0,
            manual: false,
    },
    mutations:{
        setLoader(state, {show = false, message = 'Пожалуйста, подождите. Идёт загрузка.', delay = 0, manual = false}:ILoaderState = <ILoaderState>{}) {
            state.show = show;
            state.message = message;
            state.delay = delay;
            state.manual = manual;
        },
    },
    actions:{
      setLoader({commit}, {show = false, message = 'Пожалуйста, подождите. Идёт загрузка.', delay = 1800, manual = false}:ILoaderState = <ILoaderState>{}) {
            commit('setLoader', {show, message, delay, manual});
            if (show && !manual) {
                setTimeout(() => {
                    commit('setLoader');
                    console.log('Лоадер закрылся автоматически');
                }, delay);
            }
        },
    },
    getters:{
        loader: (state):ILoaderState => state,
    },
};