import { axios } from '@/plugins/http-axios';
import { Module } from "vuex";
import { RootState } from "@/store/index";
import { IBaseOffice } from "@/store/bank";
import { PRIVILEGES } from '@/views/bank/constants';

function sortMenu(menu: IMenu[]): IMenu[] {
    const lku = menu.find(item => item.url == '/admin');
    const lkb = menu.find(item => item.url == '/bank');
    const lko = menu.find(item => item.url == '/report');

    return <IMenu[]>[lku, lkb, lko].filter(item => {
        return !!item;
    });
};
export interface IUser {
    // id: number|undefined;
    // nameEmployee: string;
    // password: string;
    // numberDocument: string;
    // dateDocument: string;
    id: string;
    username: string;
    fio: string;
    firstName: string;
    lastName: string;
    parName: string;
    email: string;
    domainName: string;
    firstPassword: boolean;
    schemaDesign: string;

    roles: IRole[];
    employee: IEmployee;
    filterSettings: {
        //м.б. пустым объектом. radar может отсутствовать, если пользователь новый
        //todo убрать radar
        "radar"?: IFilterSettings;
    };
    offices: IOfficeUser[];
    activeRole: IRole;
    signContract: boolean;
    dateDocumentStart: string;
    dateDocumentEnd: string;
}
export interface IFilterSettings {
    "limit": number;
    "offset": number;
    "substring": string;
     updateDateFrom: string;
     updateDateTo: string;
    "datefrom": string;
    "agents": string[];
    "dateto": string;
    "closedatefrom": string;
    "closedateto": string;
    //todo сделать цифрой
    "annulPeriod": number | string;
    "signers": string[];
    "takers": string[];
    "offices": number[];
    "statusid": number[];
    "sorted": {
        "field": string;
        "orderBy": 'ASC' | 'DESC' | '';
    }
};
export interface IFilterSettingsForm {
    "limit": number;
    "offset": number;
    "substring": string;
    "sorted": {
        "field": string;
        "orderBy": 'ASC' | 'DESC' | '';
    },
    appId: string;
};
interface IEmployee {
    "id": string;
    "userid": string;
    "surname"?: string;
    "name": string;
    "parname": string;
    "dateDocument"?: string;
    "numberDocument": string;
    "nameEmployee": string;
    "email": string;
    "deleted": boolean;
    "revokeFromApplication": boolean;
    "fio": string;
};

export interface IOfficeUser extends IBaseOffice {
    "regionId": number;
    "sortedItem": number;
};

export interface IMenu {
    id: number;
    url: string;
    name: string;
    parentUrl?: string;
    children: IMenu[];
    text: string;
    href: string;
    path: string;
};
export interface IAppBlock {
    id: number;
    orderNum: number;
    code: string;
    name: string;
    active: boolean;
    // value: string,
    // text: string
};
export interface IPrivileges {
    "id": number;
    "name": string;
    "description": string;
    "isCreated": boolean;
    "isRead": boolean;
    "isUpdate": boolean;
    "isDelete": boolean;
    "isExecute": boolean;
};
export interface IRole {
    id: number,
    name: string;
    active: boolean;
    menuTree: IMenu[];
    menuFlat: IMenu[];
    applicationBlocks: IAppBlock[];
    privileges: IPrivileges[];
};
interface ISelectRole {
    text: string;
    value: IRole;
};
const user: IUser = {
    id: '',
    username: '',
    fio: '',
    firstName: '',
    lastName: '',
    parName: '',
    domainName: '',
    employee: {
        "id": "",
        "userid": "",
        "name": "",
        "parname": "",
        "numberDocument": "",
        "nameEmployee": "",
        "email": "",
        "deleted": false,
        "revokeFromApplication": false,
        "fio": ""
    },
    email: '',
    firstPassword: false,
    roles: [],
    offices: [],
    activeRole: {
        id: 0,
        name: '',
        active: false,
        menuTree: [],
        menuFlat: [],
        applicationBlocks: [],
        privileges: [],
    },
    filterSettings: {},
    schemaDesign: '',
    signContract: false,
    dateDocumentStart: '',
    dateDocumentEnd: '',
};
const templateFilter: IFilterSettings = {
    takers: [],
    offices: [],
    statusid: [],
    agents: [],
    signers: [],
    updateDateFrom: '',
    updateDateTo: '',
    closedatefrom: '',
    closedateto: '',
    datefrom: '',
    dateto: '',
    annulPeriod: '',
    //todo offset переделать на page, единицы измерения должны быть в страницах, а не в строках. limit переделать на perPage, единицы измерения правильные - страница. И вообще выкинуть этот функционал и передлать на загаловки запроса
    offset: 0,
    limit: 10,
    substring: '',
    sorted: {
        field: '',
        orderBy: '',
    }
};
const templateFilterForm: IFilterSettingsForm = {
    offset: 0,
    limit: 10,
    substring: '',
    sorted: {
        field: '',
        orderBy: ''
    },
    appId: ''
};
interface IUserState {
    user: IUser;
    authorized?: 'authorized' | 'no_authorized';
    filterForm: IFilterSettingsForm;
    phonegroup: any
    agent:any
};
export default <Module<IUserState, RootState>>{
    state: {
        user: JSON.parse(JSON.stringify(user)),
        authorized: undefined,
        filterForm: JSON.parse(JSON.stringify(templateFilterForm)),
        phonegroup: {},
        agent:[]
    },
    mutations: {
        setActiveRole(state, role: IRole) {
            role.menuTree = sortMenu(role.menuTree);
            state.user.activeRole = role;
        },
        setAgent(state,value:any){
          state.agent = value
        },
        setAuthorized(state, value) {
            state.authorized = value;
        },
        setUser(state, data: IUser = user) {
            if (data.activeRole && data.activeRole.menuTree)
                data.activeRole.menuTree = sortMenu(data.activeRole.menuTree);
            for (let key in user) {
                //@ts-ignore
                state.user[key] = data[key] || user[key];
            }
        },
        _setFilter(state, filter) {
            if (!('radar' in state.user.filterSettings))
                state.user.filterSettings = Object.assign({}, state.user.filterSettings, { radar: {} });
            if (!filter) {
                state.user.filterSettings.radar = JSON.parse(JSON.stringify(templateFilter));
            } else if (state.user.filterSettings.radar) {
                for (let key in templateFilter) {
                    if (key in state.user.filterSettings.radar) {
                        if (key in filter) {
                            //@ts-ignore
                            state.user.filterSettings.radar[key] = filter[key];
                            if (state.user.filterSettings.radar && !state.user.filterSettings.radar.agents){
                              state.user.filterSettings.radar.agents = [];
                            }
                        }
                    }
                    else {
                        //@ts-ignore
                        state.user.filterSettings.radar[key] = filter[key] || templateFilter[key];
                    }
                }
            }
        },
        updatePhoneGroup(state, payload) {
            state.phonegroup = payload
        },
        resetGroup(state) {
            state.phonegroup = {}
        }
    },
    actions: {
        getUser({ commit, dispatch, getters, rootGetters }): Promise<string> {
            return axios.get<IUser>(rootGetters.uri.userInfo)
                .then(resp => {
                    // localStorage.setItem('user', JSON.stringify(resp.data));
                    commit('setAuthorized', 'authorized');
                    commit('setUser', resp.data);
                    // console.log(this.getters.role,);
                    if (!getters.role || !getters.role.id)
                        commit('setAccessRole');
                    return 'authorized';
                })
                .catch(e => {
                    console.log(e.response);
                    if (('response' in e) && (e.response instanceof Object)) {
                        if (e.response.status == 401) {
                            commit('setAuthorized', 'no_authorized');
                            return Promise.resolve('no_authorized');
                        }
                        if (e.response.status == 423) {
                            //todo убрать пробел
                            if (e.response.data.errorMessage == 'Role block ') {
                                commit('setAuthorized', 'authorized');
                                return Promise.resolve('authorized');
                            }
                            else {
                                commit('setAuthorized', 'blocked');
                                return Promise.resolve('blocked');
                            }
                        }
                    }

                    e.uri = rootGetters.uri.userInfo;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        setActiveRole({ commit, getters, dispatch, rootGetters }, data) {
            commit('setActiveRole', data);

            axios.post(rootGetters.uri.changeRole, { id: data.id })
                .then((resp) => {
                    console.log(resp.data);
                })
                .catch(e => {
                    e.uri = rootGetters.uri.changeRole;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        }

    },
    getters: {
        user: (state): IUser => state.user,
        isFirstPassword: (state): boolean => state.user.firstPassword,
        authorized: (state): string | undefined => state.authorized,
        filter: (state): IFilterSettings => {
            if (state.user.filterSettings && state.user.filterSettings.radar)
                return state.user.filterSettings.radar;
            else {
                return JSON.parse(JSON.stringify(templateFilter));
            }
        },
        // filterForm: (state):IFilterSettingsForm => {
        //   if(state.user.filterSettings )
        //     { // @ts-ignore
        //       return state.user.filterSettings;
        //     }
        //   else{
        //     return JSON.parse(JSON.stringify(templateFilterForm));
        //   }
        // },
        getAgentBlock:(state):any=>state.agent,
        filterForm: (state): IFilterSettingsForm => state.filterForm,
        role: (state): IRole => state.user.activeRole,
        listUserRoles: (state): ISelectRole[] => state.user.roles.map(item => ({ text: item.name, value: item })),
        privileges: (state, getters): IPrivileges => getters.role.privileges,
        isPrivileges: (state, getters) => (name: string): boolean => !!getters.privileges.find((priv: IPrivileges) => priv.name === name),
        //Генерация подписных форм
        hasPrivilegeGenerationOfSubscriptionForms: (state, getters): boolean => getters.isPrivileges(PRIVILEGES.GENERATION_OF_SUBSCRIPTION_FORMS),
        blocks: (state, getters): IAppBlock[] => getters.role.applicationBlocks,
        menu: (state, getters): IMenu[] => getters.role.menuTree,
        menuFlat: (state, getters): IMenu[] => getters.role.menuFlat,
        checkRoute: (state, getters) => (route: string): boolean => !!getters.menuFlat.find((item: IMenu) => item.url === route),
        offices: (state): IOfficeUser[] => state.user.offices,
        getPhoneGroup: ({ phonegroup }) => phonegroup
    },
};
