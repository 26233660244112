export default async function getApps({ context, nextMiddleware }){
    console.log('getApps')
    try {
        await context.store.dispatch('getTakers')
        await context.store.dispatch('getOffices')
        await context.store.dispatch('getStatusCount')
        await context.store.dispatch('getSigners')
        await context.store.dispatch('getSysParameters')
    }
    catch(e){
        console.error(e)
        return context.next(false)
    }
    return nextMiddleware()
}
