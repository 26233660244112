import {Module} from "vuex";
interface ISystemState{
    session: boolean;
    accessUser: boolean;
    accessRole: boolean;
};

export default <Module<ISystemState, any>>{
    state:{
        session: true,
        accessUser: true,
        accessRole: true,

    },
    mutations:{
        setSession(state, data:boolean = false){
            state.session = data;
        },
        setAccessUser(state, data:boolean = false){
            state.accessUser = data;
        },
        setAccessRole(state, data:boolean = false){
            state.accessRole = data;
        },
    },
    getters:{
        isCloseSession: (state):boolean => !state.session,
        isBlockUser: (state):boolean => !state.accessUser,
        isBlockRole: (state):boolean => !state.accessRole,
    }
};