import {Module} from "vuex";
import {IData} from "@/types/globalTypes";

interface IError{
    type: string;
    key: string;
    message: string;
    stack: string;
    browser: string;
    uri: string;
    params: IData;
    response?: IData;
};

interface IErrorState{
    name: string;
    message: string;
    fn: string;
    uri: string;
    params: IData;
    key: string;
};

export default <Module<IErrorState, any>>{
    state:{
        name: '',
        message: '',
        fn:'',
        uri:'',
        params:{},
        key:'',
    },
    mutations:{
        setError(state, {message='', stack='', key='', uri='', params={}, response=undefined}:IError=<IError>{}) {

            // console.log(message, stack, key, uri, params, response);
            // console.log(11,message, response);
            if (response) {
                const {message: message2, errorMessage} = response.data;
                const _errorMessage = message2 || errorMessage || (typeof response.data === 'string') ? response.data : message;

                if (response.status === 501 || response.status === 502) {
                    state.name = 'Внимание!'
                    state.message = 'Сервер временно недоступен, зайдите позже'
                } else if (response.status === 500) {
                    state.name = 'Внутренняя ошибка сервера';
                    state.message = _errorMessage || state.name;
                } else if (response.status === 423 || /*response.status === 409 ||*/ response.status === 401) {
                    state.name = ''
                    state.message = ''
                } else {
                    state.name = 'Ошибка'
                    state.message = _errorMessage || state.name;
                }
            }
            else {
                state.name = 'Ошибка клиента'
                state.message = message
            }

            const fn = stack.match(/^\w+/);
            if(fn && fn.length)
                state.fn = fn[0];

            state.key = key;
            state.uri = uri;
            state.params = params;
        },

        clearError(state) {
            state.name = '';
            state.message = '';
        },
    },
    actions:{
        setError({commit, dispatch}, e){
            if (('response' in e) && (e.response.status == 401 || e.response.status == 423 ))
                return;

            e.key = +new Date;
            // e.response = {status:500, data:{message:'rwerwerwe'}};
            // e.uri = 'uri';
            // e.params = {a:1};
            const data = <IError>{
                key: <string>e.key,
                message: <string>e.name + ' ' + e.message,
                stack: <string>e.stack,
                uri: '',
                params: {},

                browser: <string>navigator.userAgent,
                type: 'lkub',
                response: e?.response,
            };

            if(e.uri)
                data.uri = e.uri;

            if(e.params)
                data.params = e.params;

            dispatch('setLoader'); // Если был лоадер, а возникла ошибка, к ней не попасть
            commit('setError', data);
        },
    },
    getters:{
        error: (state):IErrorState => state,
    },
};
