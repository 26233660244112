import {axios} from "../plugins/http-axios";
import {Module} from "vuex";
import {RootState} from "@/store/index";
export interface I18n{
    [key:string]:string
};
interface I18nState{
    i18n: I18n;
    i18nLkk: I18n;
    i18nBank: I18n,
};
export default <Module<I18nState, RootState>>{
    state:{
        i18n: {},
        i18nBank: {},
        i18nLkk: {},
    },
    mutations:{
        setI18n(state, data:I18n={}) {
            state.i18n = data;
        },
        setI18nBank(state, data:I18n={}) {
            state.i18nBank = data;
        },
        setI18nLkk(state, data:I18n={}) {
            state.i18nLkk = data;
        },

    },
    actions:{
        getI18n({commit, dispatch, rootGetters}):Promise<void> {
/*
            const i18n:string|null = localStorage.getItem('i18n');
            if (i18n){
                commit('setI18n', JSON.parse(i18n));
                return Promise.resolve();
            }
*/
            // if (getters.isI18n)
            //     return Promise.resolve();

            return axios.get(rootGetters.uri.infoSystemInfoLogin)
                .then(resp => {
                    let data = {
                        ...resp.data.i18n,
                        version: resp.data.version
                    };
                    // localStorage.setItem('i18n', JSON.stringify(data));
                    commit('setI18n', data);
                    return Promise.resolve();
                })
                .catch(e => {
                    e.uri = rootGetters.uri.infoSystemInfoLogin;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        getI18nLkk({getters, commit, dispatch, rootGetters, state}):Promise<I18n> {
            if (getters.isI18nLkk)
                return Promise.resolve(state.i18nLkk);

            return axios.get<I18n>(rootGetters.uri.i18nLkk)
                .then(resp => {
                    commit('setI18nLkk', resp.data);
                    return resp.data;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.i18nLkk;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters:{
        isI18nLkk: (state):boolean => !!Object.keys(state.i18nLkk).length,
        voc: (state):I18n => {
            return {...state.i18n, ...state.i18nBank, ...state.i18nLkk};
        },
        // isI18n: (state):boolean => !!Object.keys(state.i18n).length,
        i18n: (state):I18n => state.i18n,
    },
};
