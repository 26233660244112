const accounting = require('accounting-js')
import moment from 'moment'

const accountingDecimalConfig = {
  symbol: '',
  precision: 2,
  thousand: ' ',
}

const accountingConfig = {
  symbol: '',
  precision: 2,
  thousand: ' ',
}

const precisionFourConfig = {
  symbol: '',
  precision: 4,
  thousand: ' ',
}

function sortDate(a, b, desc) {
  const aDate = moment(a, 'DD.MM.YYYY').toDate()
  const bDate = moment(b, 'DD.MM.YYYY').toDate()
  if (desc) return aDate - bDate
  else return bDate - aDate
}

function isDate(a) {
  return moment(a, 'DD.MM.YYYY', true).isValid()
}

export function formatDate(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
}
export default {
  removeFromArray(array, key, value) {
    const index = array.findIndex((obj) => obj[key] === value)
    return index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array
  },

  formatMoneyFourPrecision(number = '') {
    if (!number) return 0

    return accounting.formatMoney(number, precisionFourConfig)
  },
  formatDate,
  formatMoney(number = '') {
    if (!number) {
      return 0
    }

    if (number % 1 == 0) {
      return accounting.formatMoney(number, accountingConfig)
    } else {
      return accounting.formatMoney(number, accountingDecimalConfig)
    }
  },

  customSort(items, sortBy, sortDesc, locale, customSorters) {
    items.sort((a, b) => {
      if (sortBy.length == 1) {
        const key = sortBy[0]

        const valueOne = a[key]
        const valueTwo = b[key]
        const desc = sortDesc[0]
        if (isDate(valueOne) && isDate(valueTwo)) return sortDate(valueOne, valueTwo, desc)
        else if (!isNaN(valueOne)) {
          if (desc) {
            return valueOne - valueTwo
          } else {
            return valueTwo - valueOne
          }
        } else {
          if (desc) {
            return valueOne < valueTwo ? -1 : 1
          } else {
            return valueTwo < valueOne ? -1 : 1
          }
        }
      }
    })
    return items
  },

  getIcon(status) {
    switch (status.code) {
      case 'PULL_OFF':
      case 'REQUIREMENT_VALIDATING':
      case 'WAIT':
        return 'mdi-bell'
      case 'WARNING':
      case 'REQUIREMENT_REQUEST':
        return 'mdi-bell-minus'
      case 'EXPIRED':
        return 'mdi-bell-minus'
    }

    return 'mdi-bell-minus'
  },

  getColor(status) {
    switch (status.code) {
      case 'PULL_OFF':
      case 'REQUIREMENT_VALIDATING':
      case 'WAIT':
        return '#23be00'
      case 'WARNING':
      case 'REQUIREMENT_REQUEST':
        return 'rgb(255, 84, 0)'
      case 'EXPIRED':
        return '#ee1123'
    }

    return 'rgb(255, 84, 0)'
  },
}

export const isAppManual = (value = false) => !!value === false

export const random = (numberOfDigits = 9) => Math.round((0.5+Math.random()) * 10*numberOfDigits)

export const randomRange = (min, max) => {
  return [Math.floor(Math.random() * (max - min + 1)) + max, Math.floor(Math.random() * (max - min + 1)) + min]
}


