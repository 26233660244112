import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from './admin'
import bank from "./bank"
import report from "./report"

// import auth from './middleware/auth';
// import guest from './middleware/guest';
import middlewarePipeline from './middlewarePipeline'
import store from '../store'

Vue.use(VueRouter)


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    // meta:{
    //   middleware:[
    //     auth,
    //     guest
    //   ]
    // }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      admin,
      bank,
      report,
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach( async(to, from, next) => {
  console.log(`root from:${from.path} to:${to.path} to.middleware:`)

  if (store.getters.authorized === undefined){
    console.log('auth rule1 setData', undefined )
    try {
      // await store.dispatch('logout');
      await store.dispatch('getUser')
      // store.dispatch('initTypeColorSchema');
      // await store.dispatch('getMenu');

      await Promise.all([
        store.dispatch('getI18n'),
        store.dispatch('getDesignConfig'),
      ])

      store.dispatch('setTheme')
      document.querySelector('title').innerHTML = store.getters.voc.LKB_TITLE || ''

      // store.commit('setMenu', );
      // store.commit('setMenu', router.options.routes[1].children);
      // if (store.getters.authorized == 'authorized')
      //   await store.dispatch('createMenu');
    }
    catch(e){
      console.error(e)
      return next(false)
    }

    // store.dispatch('setTheme');
  }

  if (store.getters.authorized === 'authorized'){
    //todo При смене роли и uncaught Exception error
    if (to.name!=='Home' && !store.getters.checkRoute(to.path) && !(to.name==='application' && store.getters.checkRoute('/bank/applications'))) {
      console.log(333, 'Access Denied')
      return next('/')
    }
    console.log('auth rule2, authorized')
    if (!to.meta.middleware || !to.meta.middleware.length) {
      console.log(`root from:${from.path} to:${to.path} to.middleware:`, null)
      return next()
    }
    const middleware = to.meta.middleware
    // console.log(`root from:${from.path} to:${to.path} to.middleware:`, middleware);

    const context = {
      to,
      from,
      next,
      store,
    }

    return middleware[0]({
      context,
      nextMiddleware: middlewarePipeline(context, middleware, 1)
    })
  }
  else if (store.getters.authorized === 'no_authorized'){
    console.log('auth rule3, no_authorized')
    if (to.name === 'Login')
      return next()
    else
      return next({name: 'Login', params: { nextUrl:to.fullPath }, replace: true})
  }
  else if (store.getters.authorized === 'blocked'){
    store.commit('setAccessUser')
  }

  console.log('auth rule4, ACCESS_DENIED', store.getters.authorized)
  return next(false)


})

/*
router.beforeEach( (to, from, next) => {

  if (!to.meta.middleware || !to.meta.middleware.length) {
    console.log(`root from:${from.path} to:${to.path} to.middleware:`, null);
    return next();
  }
  const middleware = to.meta.middleware;
  console.log(`root from:${from.path} to:${to.path} to.middleware:`, middleware);

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  });

});
*/

export default router
