export default     {
    path: 'users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/admin/Users/index.vue'),
    children:[
        {
            path: 'users',
            name: 'usersList',
            component: () => import(/* webpackChunkName: "usersLkb" */ '@/views/admin/Users/UsersList.vue'),
        },
        {
            path: 'roles',
            name: 'roles',
            component: () => import(/* webpackChunkName: "rolesLkb" */ '@/views/admin/Users/RolesList.vue'),
        },

    ]
}
