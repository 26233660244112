import {Module} from "vuex";
interface IUpdateSystemState{
    updateSystem: boolean;
};

export default <Module<IUpdateSystemState, any>>{
    state:{
        updateSystem: false,
    },
    mutations:{
        onUpdate(state){
            state.updateSystem = true;
        },
        offUpdate(state) {
            state.updateSystem = false;
        },
    },
    getters: {
        updateSystem: (state):boolean => state.updateSystem,
    },
};