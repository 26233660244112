import { IUploadedDoc } from '@/types/docTypes';

export const isSigningCounterparties = (doc:IUploadedDoc) => doc.signCounterparties !== 'NOT_SIGN_COUNTERPARTIES'
export const isSigningBank = (doc:IUploadedDoc) => doc.signingBank !== 'NOT_SIGN_BANK'

export function getDocumentsForSigning(docs:IUploadedDoc[] = []){
  return docs
    .filter(doc =>
      // item.sectionCode.startsWith('SECTION_') &&
      doc.docs.length &&
      doc.signingEcp &&
      (isSigningCounterparties(doc) || isSigningBank(doc))
    )
}

export function checkExtensionFileName(fileName: string, extension:string){
  const regexp = new RegExp("\\."+extension+"$", "i");
  return regexp.test(fileName);
}

export function checkPdfExtension(filename: string) {
  return checkExtensionFileName(filename, 'pdf');
}
