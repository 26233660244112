import Vue from "vue";
import {axios} from "../plugins/http-axios";
import {Module} from "vuex";
import {RootState} from "@/store/index";
interface IDataColor{
    [key:string]:string
};
interface IColorState{
    color: IDataColor;
};

export default <Module<IColorState, RootState>>{
    state: {
        color: {},
    },
    mutations: {
        setDesignConfig(state, data:IDataColor) {
            state.color = data;
        },
    },
    actions: {
        getDesignConfig({commit, dispatch, rootGetters}):Promise<void> {
            return axios.get<IDataColor>(rootGetters.uri.designConfig)
                .then(resp => {
                    // localStorage.setItem('color', JSON.stringify(resp.data));
                    commit('setDesignConfig', resp.data);
                    return;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.color;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        initTypeColorSchema({getters}) {
            Vue.prototype.$vuetify.framework.theme.dark = getters.user.schemaDesign === 'DARK';
        },
        setTheme({state}) {
                Vue.prototype.$vuetify.framework.theme.themes.light = {
                    primary:  state.color.LKB_BG_COLOR_MAIN_WINDOW_LIGHT,
                    error: "#ff0d00",
                    secondary: "#b71c1c",
                    success: "#4caf50",
                    accent: "#bbdefb",
                    save: "#4caf50",
                    navbar: "#1976d2",
                    header: state.color.LKB_HEADER_COLOR_LIGHT,
                    sidebar: state.color.LKB_SIDEBAR_BG_COLOR_LIGHT,
                    btn_def: state.color.LKB_BTN_MAIN_COLOR_LIGHT,
                    icon_def: state.color.LKB_ICONS_MAIN_COLOR_LIGHT,

                    lkbBackgroundMainColor: state.color.LKB_BG_MAIN_COLOR_LIGHT,
                    headerColor: "#ddd",
                    lkbHeaderFontColor: state.color.LKB_HEADER_FONT_COLOR_LIGHT,
                    navbarColor: "#333",
                    footerColor: "#333",

                    checkColor: "#fb8c00",
                    radioColor: "#fb8c00",
                    selectColor: "#fb8c00",
                    inputColor: "#fb8c00",

                  loginTextCol: state.color.LKB_COLOR_TEXTS_LOGIN_LIGHT,
                   loginBtnCol: state.color.LKB_COLOR_ELEMENTS_LOGIN_LIGHT,
                    applicationsColorManual: "#dae1e6",
                    applicationsColorStatusOk: "#ccffe6",
                    applicationsColorWarning: "#ffffcc",
                    applicationsColorUndefined: "#f0f8ff",
                    applicationsColorError: "#ff99bb",

                    applicationsColorAuthorizedPersons: "#edf3fc",
                    blackWhiteColors: "#ffffff",

                    customerDocumentsForCheck: "#ffffcc",
                    customerDocumentsValid: "#ccffe6",
                    customerDocumentsNotValid: "#eca7bd",
                    customerDocumentsProcess: "#b3ebf1",

                    ApplicationStatusesColor: "#d3d3d3",

                    LkbBgColorEntryPlate: state.color.LKB_BG_COLOR_ENTRY_PLATE_LIGHT,
                    LkbBgColorLoginButton: state.color.LKB_BG_COLOR_LOGIN_BUTTON_LIGHT,
                    LkbColorSupport: state.color.LKB_COLOR_SUPPORT_LIGHT,
                    LkbColorTextsAndElement: state.color.LKB_COLOR_TEXTS_AND_ELEMENTS_LIGHT,
                    lkbColorTableHeader: state.color.LKB_COLOR_TABLE_HEADER_LIGHT,
                    LkbColorLoginElement: state.color.LKB_COLOR_TEXTS_AND_ELEMENTS_LOGIN_LIGHT,
                    LkbColorDownlIcon: state.color.LKB_COLOR_DOC_DOWNLOAD_PICTURE_LIGHT,
                    LkbColorGenerateIcon:state.color.LKB_COLOR_DOC_GENERATE_PICTURE_LIGHT,
                    LkbColorTextDownloadPicture: state.color.LKB_COLOR_TEXT_DOWNLOAD_PICTURE_LIGHT,
                    LkbColorTextGeneratePicture: state.color.LKB_COLOR_TEXT_GENERATE_PICTURE_LIGHT,

                };
                Vue.prototype.$vuetify.framework.theme.themes.dark = {

                    primary:  state.color.LKB_BG_COLOR_MAIN_WINDOW_DARK,
                    error: "#ff0d00",
                    secondary: "#b71c1c",
                    success: "#4caf50",
                    accent: "#bbdefb",
                    save: "#4caf50",
                    navbar: "#1976d2",
                    header: state.color.LKB_HEADER_COLOR_DARK,
                    lkbHeaderFontColor: state.color.LKB_HEADER_FONT_COLOR_DARK,
                    sidebar: state.color.LKB_SIDEBAR_BG_COLOR_DARK,
                    btn_def: state.color.LKB_BTN_MAIN_COLOR_DARK,
                    icon_def: state.color.LKB_ICONS_MAIN_COLOR_DARK,

                    lkbBackgroundMainColor: state.color.LKB_BG_MAIN_COLOR_DARK,
                    applicationsColorManual: "#8c98a1",
                    applicationsColorStatusOk: "#6da187",
                    applicationsColorWarning: "#adad80",
                    applicationsColorUndefined: "#b0b8bf",
                    applicationsColorError: "#ad617b",
                  loginTextCol: state.color.LKB_COLOR_TEXTS_LOGIN_DARK,
                  loginBtnCol: state.color.LKB_COLOR_ELEMENTS_LOGIN_DARK,
                    applicationsColorAuthorizedPersons: "#b0b8bf",
                    blackWhiteColors: "#000000",

                    customerDocumentsForCheck: "#adad80",
                    customerDocumentsValid: "#7fb399",
                    customerDocumentsNotValid: "#ad7889",
                    customerDocumentsProcess: "#7dadb3",

                    ApplicationStatusesColor: "#9E9E9E",

                    LkbBgColorEntryPlate: state.color.LKB_BG_COLOR_ENTRY_PLATE_DARK,
                    LkbBgColorLoginButton: state.color.LKB_BG_COLOR_LOGIN_BUTTON_DARK,
                    LkbColorSupport: state.color.LKB_COLOR_SUPPORT_DARK,
                    LkbColorTextsAndElement: state.color.LKB_COLOR_TEXTS_AND_ELEMENTS_DARK,
                    lkbColorTableHeader: state.color.LKB_COLOR_TABLE_HEADER_DARK,
                    LkbColorLoginElement: state.color.LKB_COLOR_TEXTS_AND_ELEMENTS_LOGIN_DARK,
                    LkbColorDownlIcon: state.color.LKB_COLOR_DOC_DOWNLOAD_PICTURE_DARK,
                    LkbColorGenerateIcon:state.color.LKB_COLOR_DOC_GENERATE_PICTURE_DARK,
                    LkbColorTextDownloadPicture: state.color.LKB_COLOR_TEXT_DOWNLOAD_PICTURE_DARK,
                    LkbColorTextGeneratePicture: state.color.LKB_COLOR_TEXT_GENERATE_PICTURE_DARK,
                };
        },
/*
        getDesignScheme({dispatch}) {
            return axios.get(uri.getSchema)
                .then(resp => {
                    if (/dark/i.test(resp.data))
                        Vue.prototype.$vuetify.framework.theme.dark = true;
                    return true;
                })
                .catch(e => {
                    e.uri = uri.getSchema;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
*/
    },
    getters: {
        typeColorSchema: ():'dark'|'light' => Vue.prototype.$vuetify.framework.theme.dark ? 'dark' : 'light',
        theme: (state, getters):IDataColor => Vue.prototype.$vuetify.framework.theme.themes[getters.typeColorSchema],
        logoLoginImgW: (state):string => state.color.LKB_LOGO_IMAGE_WIDTH,
        logoLoginImgH: (state):string => state.color.LKB_LOGO_IMAGE_HEIGHT,
        headerLogoImgW: (state):string => state.color.LKB_HEADER_IMG_WIDTH,
        headerLogoImgH: (state):string => state.color.LKB_HEADER_IMG_HEIGHT,
        MIRROR_REFLECTION: (state):string => state.color.MIRROR_REFLECTION,
        HIDE_FIELD_SHADOW_LOGO: (state):string => state.color.HIDE_FIELD_SHADOW_LOGO,
        DISPLAY_ABOVE_BLOCK: (state):string => state.color.DISPLAY_ABOVE_BLOCK,
        dialogWhiteTextEnable: (state):boolean => state.color.DIALOG_COLOR_TEXT_WHITE === 'true',
    },
};
