import {ActualAddress, Participants, Counterparties, Notifiers, ServiceOffice} from '@ifr/form-questionnaire/src/types/Elements'
export default [
    new ActualAddress(),
    new Participants(),
    new Counterparties(),
    new ServiceOffice(),
    new Notifiers()
]
/*
export default [
    {
      "name": "actualAddress",
      "type": "section",
      "subType": "entitySection",
      "label": "",
      "hint": "",
      "orderId": 0,
      "entity": true,
      "fields": [
        {
          "name":"address",
          "type": "field",
          "subType": "entityField",
          "label": "Адрес по которому фактически находится постоянно действующий исполнительный орган",
          labelLkb:'',
          "hint": "",
          "value": "",
          "orderId": 0,
          "entity": true
        },
        {
          "name":"matched",
          "type": "field",
          "subType": "entityField",
          "label": "Совпадает с адресом регистрации",
          labelLkb:'',
          "hint": "",
          "value": false,
          "orderId": 1,
          "entity": true
        }
      ]
    },
    {
      "name": "participants",
      "type": "section",
      "subType": "entitySection",
      "label": "Уполномоченные лица",
      "hint": "",
      "entity": true,
      "orderId": 1,

      "blocks":[
        {
          "fields": [
            {
              "entity": true,
              "orderId": 0,
              "name": "fio",
              "type": "field",
              "subType": "entityField",
              "label": "Фамилия Имя Отчество",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 1,
              "name": "position",
              "type": "field",
              "subType": "entityField",
              "label": "Должность",
              "labelLkb": "",
              "hint": "",
              "value": "Директор"
            },
            {
              "entity": true,
              "orderId": 2,
              "name": "docnumber",
              "type": "field",
              "subType": "entityField",
              "label": "Серия и номер паспорта",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 3,
              "name": "birthdate",
              "type": "field",
              "subType": "entityField",
              "label": "Дата рождения",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 4,
              "name": "issuedate",
              "type": "field",
              "subType": "entityField",
              "label": "Дата выдачи паспорта",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },

            {
              "entity": true,
              "orderId": 5,
              "name": "placeissued",
              "type": "field",
              "subType": "entityField",
              "label": "Кем выдан",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 6,
              "name": "birthplace",
              "type": "field",
              "subType": "entityField",
              "label": "Место рождения",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 7,
              "name": "unitcode",
              "type": "field",
              "subType": "entityField",
              "label": "Код подразделения",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 8,
              "name": "registerAddress",
              "type": "field",
              "subType": "entityField",
              "label": "Адрес регистрации (прописки)",
              "labelLkb": "",
              "hint": "",
              "value": {
                text: '',
                value: {}
              }
            }
          ]
        },
      ],
    },

    {
      "name": "counterparties",
      "type": "section",
      "subType": "entitySection",
      "label": "Сведения о контрагентах",
      "hint": "",
      "entity": true,
      "orderId": 2,
      "blocks":[
        {
          "fields": [
            {
              "entity": true,
              "orderId": 0,
              "name": "inn",
              "type": "field",
              "subType": "entityField",
              "label": "ИНН контрагента",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 1,
              "name": "name",
              "type": "field",
              "subType": "entityField",
              "label": "Наименование контрагента",
              "labelLkb": "",
              "hint": "",
              "value": ""
            },
            {
              "entity": true,
              "orderId": 2,
              "name": "factaddress",
              "type": "field",
              "subType": "entityField",
              "label": "Местонахождение контрагента",
              "labelLkb": "",
              "hint": "",
              "value": {
                text: '',
                value: {}
              }
            },
          ]
        }
      ],
    },

    {
      "name": "serviceOffice",
      "type": "section",
      "subType": "entitySection",
      "label": "Выберите офис обслуживания",
      "hint": "",
      "orderId": 3,
      "entity": true,
      "fields": [
        {
          "orderId": 0,
          "name":"inviteManager",
          "type": "field",
          "subType": "entityField",
          "label": "Пригласить менеджера",
          "labelLkb": "",
          "hint": "",
          "entity": true,
          "value": false
        },
        {
          "orderId": 1,
          "name":"officeId",
          "type": "field",
          "subType": "entityField",
          "label": "Офис обслуживания",
          "labelLkb": "",
          "hint": "",
          "entity": true,
          "value": 0
        }
      ]
    },
    {
      "name": "notifiers",
      "type": "section",
      "subType": "entitySection",
      "label": "Уведомлять о состоянии заявки",
      "hint": "",
      "orderId": 4,
      "entity": true,
      "fields": [
        {
          "orderId": 0,
          "name": "phone",
          "type": "field",
          "subType": "entityField",
          "label": "По телефону:",
          "labelLkb": "",
          "hint": "",
          "entity": true,
          "value": true
        },
        {
          "orderId": 1,
          "name": "email",
          "type": "field",
          "subType": "entityField",
          "label": "По эл. почте:",
          "labelLkb": "",
          "hint": "",
          "entity": true,
          "value": true
        }
      ]
    },
  ];*/
