export default     {
    path: 'agents',
    name: 'agents',
    component: () => import(/* webpackChunkName: "agents" */ '@/views/admin/Agents/index.vue'),
    children:[
        {
            path: 'agent-user',
            name: 'agentUser',
            component: () => import(/* webpackChunkName: "agentUser" */ '@/views/admin/Agents/AgentsList.vue'),
        },
        {
            path: 'agent-list',
            name: 'agentList',
            component: () => import(/* webpackChunkName: "agentList" */ '@/views/admin/Agents/RolesList.vue'),
        },
      {
        path: 'bank-materials',
        name: 'bankMaterials',
        component: () => import(/* webpackChunkName: "agentList" */ '@/views/admin/Agents/BankMaterials.vue'),
      },
    ]
}