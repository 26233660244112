import getCommonDataBank from "@/router/middleware/getCommonDataBank"
import getApps from "@/router/middleware/getApps"
import getApp from "@/router/middleware/getApp"
import getSysParameters from "@/router/middleware/getSysParameters"
import getSounds from "@/router/middleware/getSounds"
export default {
    name: 'bank',
    path: '/bank',
    component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/index.vue'),
    children: [
        {
            name: 'clients',
            path: 'clients',
            component: () => import(/* webpackChunkName: "clients" */ '@/views/bank/Clients.vue'),
            props: true,
        },
        {
            path: 'applications',
            name: 'applications',
            component: () => import(/* webpackChunkName: "applications" */ '@/views/bank/Applications.vue'),
            meta:{
              middleware:[
                  getCommonDataBank,
                  getApps,
                  getSounds,
              ]
            }
        },
        {
            name: 'pending-list',
            path: 'pending-list',
            component: () => import(/* webpackChunkName: "clients" */ '@/views/bank/PendingList.vue'),
            props: true,
        },
        {
            path: 'application/:id',
            name: 'application',
            component: () => import(/* webpackChunkName: "application" */ '@/views/bank/Application.vue'),
            props: true,
            meta:{
                middleware:[
                    getCommonDataBank,
                    getApp,
                    getSysParameters,
                    getSounds,
                ]
            }
        },
    ]
}
