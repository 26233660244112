import getCommonDataBank from "@/router/middleware/getCommonDataBank"
import getApps from "@/router/middleware/getApps"

export default {
    name: 'appReport',
    path: 'application-report',
    component: () => import(/* webpackChunkName: "appReport" */ '@/views/report/appReport/index'),
    meta:{
        middleware:[
            getCommonDataBank,
            getApps,
        ]
    }
}
