import {Module} from "vuex";
interface IPagingState{
    perPage: number;
    page: number;
    totalCount: number;
};
export default <Module<IPagingState, any>>{
    state:{
        perPage:10,
        page:1,
        totalCount:0,
    },
    mutations:{
        setPerPage(state, count:number = 10){
            state.perPage = count;
        },
        setPage(state, count:number = 1){
            state.page = count;
        },
        setTotalCount(state, count:number = 0){
            state.totalCount = count;
        },
    },
    actions:{
    },
    getters:{
        perPage: (state):number => state.perPage,
        page: (state):number => state.page,
        totalCount: (state):number => state.totalCount,
        offsetRows: (state, getters):number => (getters.page-1) * getters.perPage,
        pageCount: (state, getters):number => Math.ceil(getters.totalCount / getters.perPage),
    },
};
