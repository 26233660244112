export default     {
    path: 'internationalization',
    name: 'internationalization',
    // component: () => import(/* webpackChunkName: "internationalization" */ '@/views/admin/Internationalization/interlkb.vue'),
    component: () => import(/* webpackChunkName: "internationalization" */ '@/views/admin/Internationalization/index.vue'),
    children:[
        {
            path: 'text-settings',
            name: 'interLkb',
            component: () => import(/* webpackChunkName: "textSettings" */ '@/views/admin/Internationalization/interlkb.vue'),
        },
        // todo на бэкенде добавить сохранение и получения данных, по разным видам ЛКБ ЛКК
        // {
        //     path: 'inter-lkk',
        //     name: 'interLkk',
        //     component: () => import(/* webpackChunkName: "interLkk" */ '@/views/admin/Internationalization/interlkk.vue'),
        // },
    ]
}
