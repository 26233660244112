<template>
  <v-dialog :value="isCloseSession" persistent max-width="500">
    <v-card
        class="white white--text login-block pt-5"
        color="navbar">
      <h3 class="text-center text--white mb-3">Активный сеанс работы в Личном кабинете закончился. Для
        продолжения работы вам необходимо осуществить повторный вход в Личный кабинет</h3>
      <div class=" text-center w-100 d-block mb-3">
        <v-icon size="55" color="write">
          mdi-account-clock-outline
        </v-icon>
      </div>
      <v-card-text class="signin-card-login">
        <v-form ref="form" lazy-validation class="white--text">
          <v-text-field
            :value="user.username"
            label="Логин"
            prepend-icon="mdi-face"
            type="text"
            :readonly="true">
          </v-text-field>
          <v-text-field
            clearable
            v-model="model.password"
            :rules="[rules.required, rules.password]"
            label="Пароль"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            color="white"
            class="white--text"
            @keyup.enter="login">
          </v-text-field>
          <transition name="slide-x-transition">
            <v-row class="pl-3 text-center"
               v-show="loginError">
              <v-icon color="error">
                mdi-alert-circle
              </v-icon>
              <strong class="pt-0 my-0 ml-2 error--text" >
                Введен неверный пароль
              </strong>
            </v-row>
          </transition>
        </v-form>
        <v-row>
            <v-btn
              color="primary"
              class="ma-2"
              width="150"
              @click="logout">
                Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ma-2"
              width="150"
              @click="login">
                Продлить сессию
            </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {mapGetters} from 'vuex';
import {defineComponent} from "vue";
export default defineComponent({
  name: "Reauthorization",
  data() {
    return {
      model: {
        password: <string>'',
      },
      showPassword: <boolean>false,
      loginError: <boolean>false,
      rules:{
        required: (val:string) => !!val || 'Обязательно для заполнения',
        password: (val:string) => /^[\d\w]{1,18}$/i.test(val) || 'Неправильный формат',
      },
    };
  },
  computed: {
    ...mapGetters({
      uri: 'uri',
      isCloseSession: 'isCloseSession',
      user: 'user'
    }),
  },
  methods:{
    login(){
      //@ts-ignore
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append('username', this.user.username);
        params.append('password', this.model.password);
        this.$axios.post(this.uri.login, params)
          .then(resp => {
              this.$store.commit('setSession', true);
            },
            reject => {
              if (reject.response.status == 401) {
                this.loginError = true;
                setTimeout(() => {
                    this.loginError = false;
                }, 2000);
              }
              else
                return Promise.reject(reject);
            }
          )
          .catch(e => {
            e.uri = 'POST: ' + this.uri.login;
            e.params = this.model;
            // this.setLoader();
            this.$store.dispatch('setError', e);
          });
      }
    },
    logout(){
      this.$store.dispatch('logout')
        .then(()=> {
          this.$store.commit('setSession', true);
          this.$router.push({name: 'Login'});
        });

    },
  },
})
</script>
<style scoped>
</style>
