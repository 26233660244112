export default async function getCommonDataBank({ context, nextMiddleware }){
    console.log('getCommonDataBank')
    try {
        await context.store.dispatch('getEnv')
    }
    catch(e){
        console.error(e)
        return context.next(false)
    }
    return nextMiddleware()
}
