import reports from "@/router/report/reports"
import analytic from "@/router/report/analytic"
import processing from "@/router/report/processing"
import appReport from "@/router/report/appReport"
export default {
    name: 'report',
    path:'/report',
    component: () => import(/* webpackChunkName: "report" */ '@/views/report/index.vue'),
    children: [
        reports,
        analytic,
        processing,
        appReport
    ]
}
