<template>
  <v-app>
    <UpdateSystem />
    <Success />
    <Error />
    <Loader />
    <Reauthorization />
    <PasswordConfirmation />
    <BlockRole />
    <BlockUser />
    <router-view />
  </v-app>
</template>
<script>
import Error from '@/components/Error.vue'
import Loader from '@/components/Loader.vue'
import UpdateSystem from "@/components/UpdateSystem.vue"
import Reauthorization from "@/components/Reauthorization.vue"
import PasswordConfirmation from "@/components/PasswordConfirmation.vue"
import Success from "@/components/Success/index.vue"
import BlockRole from "@/components/BlockRole.vue"
import BlockUser from "@/components/BlockUser.vue"
import { mapGetters } from "vuex"
export default {
  name: 'App',
  components: {
    Success,
    Reauthorization,
    UpdateSystem,
    PasswordConfirmation,
    Loader,
    Error,
    BlockRole,
    BlockUser,
  },
  computed: {
    ...mapGetters({
      app: 'app',
      authorized: 'authorized',
      flag: 'flag',
    })
  }
}
</script>
<style lang="sass">
    @import 'src/assets/style/main'
    .h-ov
      display: flex
      justify-content: center
      position: relative
      &_s
        display: none
    .h-ov:hover .h-ov_s
      min-width: 260px!important
      max-width: 400px!important
      width: 100%!important
      white-space: normal
      left: 0
      position: absolute
      z-index: 99999!important
      top: 16px
      padding: 6px 8px
      display: block
      background-color: rgba(0,0,0, 0.54)
      cursor: pointer
      color: white

    .wrap-steps
        height: calc(100vh - 223px) !important
        overflow: auto !important
        overflow-x: hidden !important
    .v-input .v-label
        height: auto !important
    ::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
      background-color: #F5F5F5
    ::-webkit-scrollbar
      width: 10px
      height: 10px
    ::-webkit-scrollbar-thumb
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
      background-color: rgba(#9E9E9E, 0.8)
    ::-webkit-scrollbar-thumb:hover
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
      background-color: rgba(#9E9E9E, 1)
    @media (max-width: 959px)
    @media (max-width: 959px)
        .wrap-steps
            height: calc(100vh - 200px) !important
    p
        margin-bottom: 0!important
    .pl-indent
      padding-left: 0!important
    .wrap-form-width
      overflow-y: auto!important
      padding: 20px 15% 24px 15%!important
      height: calc(100vh - 70px) !important
    @media (max-width: 1400px)
      .wrap-form-width
        padding-left: 5%!important
        padding-right: 5%!important
    @media (max-width: 992px)
      .wrap-form-width
        padding-left: 12px!important
        padding-right: 12px!important
    .v-data-table__wrapper tbody td
        border: 1px solid #eee !important
    .apexcharts-menu-item
      min-width: 102px
    body .f-g-auto .v-btn__content
      flex-wrap: wrap!important
      flex-grow: 1!important
      flex-shrink: 1!important
      flex-basis: content!important
    body .f-g-auto .v-btn:not(.v-btn--round).v-size--default
      height: auto!important
      min-height: 40px!important
    html body .app-t .v-data-table .v-data-table__wrapper thead th
     border: 1px solid #E0E0E0!important
    .draggable-element
      background-color: rgba(0, 149, 255, 0.11)
    .draggable-element > div:first-child
      background-color: rgba(0, 149, 255, 0.11)

</style>
