export default async function getSounds({ context, nextMiddleware }){
    console.log('getSounds')
    try {
        if (!context.store.getters.isSounds) {
            let sounds = [
                'alert',
                'click',
                'click1',
                'message',
                'tap',
            ]
            await context.store.dispatch('setSounds', sounds)
        }
    }
    catch(e){
        console.error(e)
        return context.next(false)
    }
    return nextMiddleware()
}
