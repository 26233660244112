<template>
    <v-dialog :value="isBlockRole" persistent max-width="500" v-if="isBlockRole && !isFirstPassword">
        <v-card
                class="white white--text login-block pt-5"
                color="navbar"
                height="360">
            <h3 class="text-center text--white mb-3">Выберите роль для входа в систему</h3>
            <div class=" text-center w-100 d-block mb-3">
                <v-icon size="55" color="write">
                    mdi-account-clock-outline
                </v-icon>
            </div>
            <v-card-text class="signin-card-login">
                <v-form ref="form" lazy-validation class="white--text">
                    <v-text-field
                            :value="user.username"
                            label="Логин"
                            prepend-icon="mdi-face"
                            type="text"
                            :readonly="true">
                    </v-text-field>
                    <v-select
                        :menu-props="{offsetY: true}"
                        :items="listUserRoles"
                        v-model="selectedRole"
                        :rules="[rules.required]"
                        label="Выберите роль"
                        outlined
                        dense
                    />
                </v-form>
                <v-row>
                        <v-btn
                                color="primary"
                                class="ma-2"
                                width="150"
                                @click="logout">
                            Выход
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                class="ma-2"
                                width="150"
                                @click="selectRole">
                            Выбрать роль
                        </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import {mapActions, mapGetters} from 'vuex';
import {defineComponent} from "vue";
export default defineComponent({
  name: "BlockRole",
  data() {
    return {
      selectedRole: <null|string>null,
      rules:{
        required: (val:string) => !!val || 'Обязательно для заполнения',
      },
    };
  },
  computed: {
    ...mapGetters({
      uri: 'uri',
      isBlockRole: 'isBlockRole',
      isFirstPassword: 'isFirstPassword',
      user: 'user',
      listUserRoles: 'listUserRoles',
    }),
  },
  watch:{
    //todo не совсем понятно зачем это нужно, вреда не делает, просто лишний запрос. Нужно разобраться
    isBlockRole(val:boolean){
      if (val) {
        this.getUser();
        this.selectedRole = null;
      }
    },
  },
  methods:{
    ...mapActions({
      setActiveRole: 'setActiveRole',
      getUser: 'getUser'
    }),
    selectRole(){
      //@ts-ignore
      if (this.$refs.form.validate()) {
        this.setActiveRole(this.selectedRole);
        this.$store.commit('setAccessRole', true);
      }
    },
    logout(){
      this.$store.dispatch('logout')
        .then(()=> {
          this.$store.commit('setAccessRole', true);
          this.$router.push({name: 'Login'});
        });
    },
  },
})
</script>

<style scoped>

</style>
