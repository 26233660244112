import {axios} from "@/plugins/http-axios";
import {Module} from "vuex";
import {RootState} from "@/store/index";
interface IAction{
    show: boolean,
    title: string,
    comment: string,
    titleComment: string,
    method: string,
    actionId: string,
    appId: string,
    titleButton: string
    disabled?: boolean
};
const modelAction:IAction = {
    show: false,
    title: '',
    comment: '',
    titleComment: '',
    method: '_empty',
    actionId: '',
    appId: '',
    titleButton: '',
    disabled: false
};
interface IAccount{
    show: boolean;
    appInn: string;
    appId: string;
};
interface IBaseButton{
    color: string;
    icon: string;
};
interface IButton{
    [key:string]:IBaseButton;
};
interface IExtendButton extends IBaseButton{
    method: string;
};

interface IActionState{
    modalReserveAccount: IAccount;
    reserveCurrencyAccount: IAccount;
    currencyTransitAccount: IAccount;
    modelAction: IAction;
    buttons: IButton,
};
export default <Module<IActionState, RootState>>{
    state: {
        modalReserveAccount:{
            show: false,
            appInn: '',
            appId: '',
        },
        reserveCurrencyAccount:{
            show: false,
            appInn: '',
            appId: '',
        },
        currencyTransitAccount:{
            show: false,
            appInn: '',
            appId: '',
        },
        modelAction: JSON.parse(JSON.stringify(modelAction)),
        buttons: {
            'OK': {
                color: 'primary',
                icon: 'mdi-briefcase-upload',
                //name: 'Возврат'
            },
            'SIGN': {
                color: 'primary',
                icon: 'mdi-briefcase-edit',
                // name: 'В 115-ФЗ', 'Открыть счет'w
            },
            'REFUSE': {
                color: 'error',
                icon: 'mdi-briefcase-remove',
                //name: 'Отказать'
            },
            'ACQUIRE': {
                color: 'success',
                icon: 'mdi-briefcase-plus',
                //name: 'Взять в работу'
            },
            'PASS': {
                color: 'error',
                icon: 'mdi-briefcase-minus',
                //name: 'Снять с работы'
            },
            'OPEN_ACCOUNT': {
                color: 'success',
                icon: 'mdi-briefcase-check',
            },
            'OPEN_ACCOUNT_WITHOUT_INTEGRATION': {
                color: 'success',
                icon: 'mdi-briefcase-check',
            },
            'RECHECK': {
                color: 'primary',
                icon: 'mdi-account',
                // name: 'Обновить данные проверок'
            },
            'CHANGE_COMMENT': {
                color: 'primary',
                icon: 'mdi-account',
            },
            'CHANGE_RESULT_CHECK': {
                color: 'primary',
                icon: 'mdi-account',
            },
            'SIGNING_AFTER_VERIFICATION': {
                color: 'primary',
                icon: 'mdi-briefcase-edit',
                //Подписание после сверки
            },
            'NEXT_STATUS_AFTER_VERIFICATION': {
                color: 'primary',
                icon: 'mdi-briefcase-upload',
                //Переход к следующему статусу после сверки
            },
            /*
            :{
                color:,
                icon:,
                action:,
            },

            */
        },
    },
    mutations: {
        setActionModal(state, data:IAction = modelAction) {
            let key: keyof IAction;
            for (key in modelAction) {
                //@ts-ignore
                state.modelAction[key] = data[key] || modelAction[key];
            }
        },
        //Подписка на всплывающее событие
        actionCompleted(state, appId) {
        },
        openModalReserveAccount(state, {show=true, inn='', id=''}){
            state.modalReserveAccount.show = show;
            state.modalReserveAccount.appInn = inn;
            state.modalReserveAccount.appId = id;
        },
        openModalReserveCurrencyAccount(state, {show=true, inn='',  id=''}){
            state.reserveCurrencyAccount.show = show;
            state.reserveCurrencyAccount.appInn = inn;
            state.reserveCurrencyAccount.appId = id;
        },
        openModalCurrencyTransitAccount(state, {show=true, inn='',  id=''}){
            state.currencyTransitAccount.show = show;
            state.currencyTransitAccount.appInn = inn;
            state.currencyTransitAccount.appId = id;
        },

    },
    actions: {
        'ACQUIRE'({state, dispatch, commit, rootGetters}, {appId}) {
            axios.get(rootGetters.uri.actionsWork(appId))
                .then(resp => {
                    commit('actionCompleted', appId);
                    // dispatch('initTable', {root: true});
                })
                .catch(e => {
                    e.uri = rootGetters.uri.actionsWork(appId);
                    dispatch('setError', e);
                });
        },
        'PASS'({state, dispatch, commit, rootGetters}, {appId}) {
            axios.delete(rootGetters.uri.actionsWork(appId))
                .then(resp => {
                    commit('actionCompleted', appId);
                    // dispatch('initTable', {root: true});
                })
                .catch(e => {
                    e.uri = 'DELETE: ' + rootGetters.uri.actionsWork(appId);
                    dispatch('setError', e);
                });
        },
        'REFUSE'({commit}, {appId, actionId, title = ''}) {
            commit('setActionModal', {
                show: true,
                method: 'nextStatus',
                appId,
                actionId,
                title: title.endsWith('?') ? title : `${title}?`,
                titleComment: 'Комментарий',
                titleButton: 'Подтвердить'
            });
        },
        'OK'({commit}, {appId, actionId}) {
            commit('setActionModal', {
                show: true,
                method: 'nextStatus',
                appId,
                actionId,
                title: 'Подтвердить переход?',
                titleComment: 'Комментарий',
                titleButton: 'Подтвердить'
            });
        },
        'OPEN_ACCOUNT'({commit, getters}, {appId, actionId}) {
            commit('setActionModal', {
                show: true,
                method: 'openAccount',
                appId,
                actionId,
                title: getters.account.name + '?',
                titleComment: '',
                titleButton: 'Подтвердить'
            });
        },
      'OPEN_ACCOUNT_WITHOUT_INTEGRATION'({commit, getters}, {appId, actionId}) {
        console.log(' getters.account.name ',  getters.accountWithoutIntegration )
        commit('setActionModal', {
          show: true,
          method: 'openAccount',
          appId,
          actionId,
          title: getters.accountWithoutIntegration.name + '?',
          titleComment: '',
          titleButton: 'Подтвердить'
        });
      },
        'SIGN'({commit}, {appId, actionId}) {
            commit('setActionModal', {
                show: true,
                method: 'nextStatus',
                appId,
                actionId,
                title: 'Подписать заявку?',
                titleComment: 'Комментарий',
                titleButton: 'Подписать'
            });
        },
        'SIGNING_AFTER_VERIFICATION'({commit}, {appId, actionId}) {
            commit('setActionModal', {
                show: true,
                method: 'nextStatus',
                appId,
                actionId,
                title: 'Подписать заявку?',
                titleComment: 'Комментарий',
                titleButton: 'Подписать'
            });
        },
        'NEXT_STATUS_AFTER_VERIFICATION'({commit}, {appId, actionId}) {
            commit('setActionModal', {
                show: true,
                method: 'nextStatus',
                appId,
                actionId,
                title: 'Подтвердить переход?',
                titleComment: 'Комментарий',
                titleButton: 'Подтвердить'
            });
        },

        nextStatus({state, commit, dispatch, rootGetters}) {
            axios.post(rootGetters.uri.appAction(state.modelAction), {comment: state.modelAction.comment})
                .then(resp => {
                    state.modelAction.disabled = false
                    commit('actionCompleted', state.modelAction.appId);
                    commit('setActionModal');
                    dispatch('getStatusCount');
                })
                .catch(e => {
                    if (e.response && e.response.status == 409) {
                        dispatch('addSuccess', { message: e.response.data.errorMessage, color: 'error'});
                        state.modelAction.disabled = true
                    } else {
                        e.uri = 'POST: ' + rootGetters.uri.appAction(state.modelAction);
                        e.params = {comment: state.modelAction.comment};
                        dispatch('setError', e);
                    }
                });
        },
        openAccount({state, commit, dispatch, rootGetters}) {
            axios.post(rootGetters.uri.appAction(state.modelAction), {
                accountnumber: '', // На старом клиенте, отправлятся ''
                prkoproduct: 0 // На старом клиенте, отправлялся 0
            })
                .then(resp => {
                    state.modelAction.disabled = false
                    commit('actionCompleted', state.modelAction.appId);
                    commit('setActionModal');
                    dispatch('getStatusCount');
                })
                .catch(e => {
                    if (e.response && e.response.status == 409) {
                        dispatch('addSuccess', { message: e.response.data.message, color: 'error'});
                        state.modelAction.disabled = true
                    } else {
                        e.uri = 'POST: ' + rootGetters.uri.appAction(state.modelAction);
                        e.params = {accountnumber: '', prkoproduct: 0};
                        dispatch('setError', e);
                    }
                });
        },
        '_empty'() {
        },
    },
    getters: {
        isAvailabilityButtons: state => ({type=''}):boolean => {
            switch (type) {
                case 'RECHECK':
                case 'CHANGE_COMMENT':
                case 'CHANGE_RESULT_CHECK':
                case 'CHANGE_DATE_LIFE':
                case 'RETURN_FOR_COMPLETE':
                    return false;
                default:
                    return true;
            }
        },
        // buttons: (state):IButton => state.buttons,
        getButton: state => ({type=''}):IExtendButton => {
            return state.buttons[type] ? {
                ...state.buttons[type],
                method: type
            } : {color: 'primary', icon: 'mdi-account', method: '_empty'};
        },
        modelAction: (state):IAction => state.modelAction,
        // showModalReserveAccount: state => state.show,
        modalReserveAccount: (state):IAccount => state.modalReserveAccount,
        reserveCurrencyAccount: (state):IAccount => state.reserveCurrencyAccount,
        currencyTransitAccount: (state):IAccount => state.currencyTransitAccount,
/*
        isShowReservationAccount: (state, getters) => (app:IApplication):boolean => {
            if (!getters.flag(getters.cons.FLAG_SELECT_PRODUCT_WHEN_OPENING)) {
                if (app.actualStatus.info === 'PROGRESS') {
                    let resolution = app.resolution;

                    if (resolution === undefined)
                        return true;

                    else if (resolution.type === 'ReservedAccount' && resolution.accountnumber !== undefined)
                        return false;

                    if (app.opened !== undefined && !app.opened)
                        return true;
                }
            }

            return false;
        },
*/
    },
};

