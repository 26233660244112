export default     {
    path: 'administration',
    name: 'administration',
    component: () => import(/* webpackChunkName: "administration" */ '@/views/admin/Administration/index.vue'),
    children:[
        {
            path: 'main',
            name: 'admMain',
            component: () => import(/* webpackChunkName: "admMain" */ '@/views/admin/Administration/admmain.vue'),
        },
        {
            path: 'lkb',
            name: 'admLkb',
            component: () => import(/* webpackChunkName: "admLkb" */ '@/views/admin/Administration/admlkb.vue'),
        },
        {
            path: 'lkk',
            name: 'admLkk',
            component: () => import(/* webpackChunkName: "admLkk" */ '@/views/admin/Administration/admlkk.vue'),
        },
        {
            path: 'marketing',
            name: 'admMarketing',
            component: () => import(/* webpackChunkName: "admMarketing" */ '@/views/admin/Administration/AdmMarketing.vue'),
        }
    ]
}
