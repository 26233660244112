<template>
  <v-dialog :value="isFirstPassword" persistent max-width="500">
    <v-card
      class="white white--text login-block pt-5"
      color="navbar">
      <h3 class="text-center text--white mb-3">Смените пароль</h3>
      <div class=" text-center w-100 d-block mb-3">
        <v-icon size="55" color="write">
          mdi-account-clock-outline
        </v-icon>
      </div>
      <v-card-text class="signin-card-login">
        <v-form ref="form" lazy-validation class="white--text">
          <v-text-field
            :value="user.username"
            label="Логин"
            prepend-icon="mdi-face"
            type="text"
            :readonly="true"/>
          <v-text-field
            clearable
            v-model="model.password"
            :rules="[rules.required, rules.password]"
            label="Новый пароль"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            color="white"
            class="white--text"/>
          <v-text-field
            clearable
            v-model="newPassword"
            :rules="[rules.required, verifyPassword]"
            label="Подтвердите пароль"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            color="white"
            class="white--text"
            @keyup.enter="confirmPassword"/>
        </v-form>
        <v-row class="d-flex" style="padding-left: 6px; padding-right: 18px;">
          <v-btn
            color="primary"
            class="ma-2"
            style="width: 100%"
            @click="confirmPassword">
              Продтвердить изменение пароля
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {mapGetters} from 'vuex';
import {defineComponent} from "vue";
export default defineComponent({
  name: "PasswordConfirmation",
  data() {
    return {
      model: {
        password: <string>'',
      },
      newPassword: <string>'',
      showPassword: <boolean>false,
      rules:{
        required: (val:string) => !!val || 'Обязательно для заполнения',
        password: (val:string) => /^[\d\w]{1,18}$/i.test(val) || 'Неправильный формат',
      },
    };
  },
  computed: {
    ...mapGetters({
      uri: 'uri',
      isFirstPassword: 'isFirstPassword',
      user: 'user'
    }),
  },
  methods:{
    verifyPassword():boolean|string {
      return this.model.password == this.newPassword || 'Пароль неправильный'
    },
    confirmPassword(){
      //@ts-ignore
      if (this.$refs.form.validate()) {
        const model = {password: this.model.password};
        this.$axios.put(this.uri.changePassword, model)
          .then(resp=>{
            this.$store.dispatch('addSuccess',  {message:'Пароль изменён'});
            this.$store.dispatch('getUser');
          })
          .catch(e => {
            e.uri = 'POST: '+this.uri.updateUser;
            e.params = model;
            this.$store.dispatch('setError', e);
          });
      }
    },
  },
})
</script>
<style lang="sass">
  .theme--light .login-block .v-input input
      color: #fff!important
  .theme--light .login-block .v-label
      color: #fff!important
  .theme--light .login-block .v-icon
      color: #fff!important
  .theme--light .login-block .v-counter
      color: #fff!important
  .v-responsive__content
      overflow-y: auto !important
</style>
