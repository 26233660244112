

export default {
    path: 'directories',
    name: 'directories',
    component: () => import(/* webpackChunkName: "directories" */ '@/views/admin/Directories/index.vue'),
    children:[
        {
            path: 'banks-and-offices',
            name: 'banksAndOffices',
            component: () => import(/* webpackChunkName: "banksAndOffices" */ '@/views/admin/Directories/BanksAndOffices.vue'),
        },
        {
            path: 'bank-questionnairies',
            name: 'bankQuestionnairies',
            component: () => import(/* webpackChunkName: "bankQuestionnairies" */ '@/views/admin/Directories/BankQuestionnairies.vue'),
        },
        {
            path: 'checks',
            name: 'checks',
            component: () => import(/* webpackChunkName: "checks" */ '@/views/admin/Directories/Checks.vue'),
        },
        {
            path: 'application-statuses',
            name: 'applicationStatuses',
            component: () => import(/* webpackChunkName: "applicationStatuses" */ '@/views/admin/Directories/ApplicationStatuses.vue'),
        },
        {
            path: 'status-model',
            name: 'statusModel',
            component: () => import(/* webpackChunkName: "statusModel" */ '@/views/admin/Directories/StatusModel.vue'),
        },
        {
            path: 'documents-types',
            name: 'documentsTypes',
            component: () => import(/* webpackChunkName: "documentsTypes" */ '@/views/admin/Directories/DocumentsTypes.vue'),
        },
        {
            path: 'setup-dynamics',
            name: 'setupDynamics',
            component: () => import(/* webpackChunkName: "setupDynamics" */ '@/views/admin/Directories/SetupDynamics.vue'),
        },
        {
            path: 'integration-abs',
            name: 'integrationAbs',
            component: () => import(/* webpackChunkName: "integrationAbs" */ '@/views/admin/Directories/IntegrationAbs.vue'),
        },
        {
            path: 'notifications',
            name: 'notifications',
            component: () => import(/* webpackChunkName: "notifications" */ '@/views/admin/Directories/Notifications.vue'),
        },
        {
            path: 'about-system',
            name: 'aboutSystem',
            component: () => import(/* webpackChunkName: "aboutSystem" */ '@/views/admin/Directories/AboutSystem.vue'),
        },
        {
            path: 'reserve-currency-accounts',
            name: 'reserveCurrencyAccounts',
            component: () => import(/* webpackChunkName: "aboutSystem" */ '@/views/admin/Directories/ReserveCurrencyAccounts.vue'),
        },
        {
            path: 'id-currency-account',
            name: 'IDCurrencyAccount',
            component: () => import(/* webpackChunkName: "aboutSystem" */ '@/views/admin/Directories/IDCurrencyAccount.vue'),
        },
        {
            path: 'jobs',
            name: 'jobs',
            component: () => import(/* webpackChunkName: "jobs" */ '@/views/admin/Directories/Jobs.vue'),
        },
        {
            path: 'storage-integration',
            name: 'storageIntegration',
            component: () => import(/* webpackChunkName: "StorageIntegration" */ '@/views/admin/Directories/StorageIntegration.vue'),
        },
        {
          path: 'phone-list',
          name: 'phoneList',
          component: () => import(/* webpackChunkName: "PhoneList" */ '@/views/admin/Directories/PhoneList.vue'),
        },
        {
          path: 'mango-integration',
          name: 'mangoItegration',
          component: () => import(/* webpackChunkName: "MangoItegration" */ '@/views/admin/Directories/MangoItegration.vue'),
        },
        {
          path: 'rafp-integration',
          name: 'rafpItegration',
          component: () => import(/* webpackChunkName: "RAFPItegration" */ '@/views/admin/Directories/RAFPIntegration.vue'),
        },
      {
        path: 'referral-links',
        name: 'referralLinks',
        component: () => import(/* webpackChunkName: "ReferralLinks" */ '@/views/admin/Directories/ReferralLinks.vue'),
      },
      {
        path: 'phone-group',
        name: 'phoneGroup',
        component: () => import(/* webpackChunkName: "ReferralLinks" */ '@/views/admin/Directories/PhoneGroup.vue'),
      },
      {
        path: 'application-sections',
        name: 'applicationSections',
        component: () => import(/* webpackChunkName: "ReferralLinks" */ '@/views/admin/Directories/ApplicationSections.vue'),
      },
    ]
}
