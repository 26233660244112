import users from '@/router/admin/users'
import agents from '@/router/admin/agents'
import directories from '@/router/admin/directories'
import files from '@/router/admin/files'
import internationalization from '@/router/admin/internationalization'
import administration from '@/router/admin/administration'
import formBuilder from '@/router/admin/formBuilder'
import lkkUserApi from '@/router/admin/lkkUserApi'

export default {
  name: 'admin',
  path: '/admin',
  component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/index.vue'),
  children: [directories, files, users, administration, internationalization, formBuilder, agents, lkkUserApi],
}
