import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify.js';
//@ts-ignore
import VueMask from 'v-mask'
// @ts-ignore
import VueExcelViewer from '@uublue/vue-excel-viewer';
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css';
Vue.use(VueExcelViewer)
//@ts-ignore
import {VueMaskFilter} from 'v-mask'

import {plugin} from "@ifr/form-questionnaire";
Vue.use(plugin);

import {AxiosStatic} from "axios";
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
};
import {axios} from '@/plugins/http-axios.js';
/*

class ErrorDataType extends Error {
  constructor(message='') {
    super(message);
    this.name = "ErrorDataType";
  };
}
*/
import VueMoment from "vue-moment";
import moment from "moment";
import polyfills from "@/plugins/polyfills.js";
// import mock from "@/mock";
// Vue.use(FormQuestionnaire);

Vue.use(VueMask)
Vue.filter('VMask', VueMaskFilter)
moment.locale('ru');
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$vuetify = vuetify;

Vue.config.productionTip = false;

if (process.env.NODE_ENV == 'production') {
  Vue.config.errorHandler = function (e, vm, info) {
    e.name = info + ' ' + e.name;
    if (vm?.$store) {
      vm.$store.dispatch('setError', e);
      // console.error(e.stack);
    }
  };
}

Vue.config.warnHandler = function(msg, vm, trace) {
  if (vm?.$store) {
    vm.$store.dispatch('setError', new Error(msg));
  }
  console.error(msg+trace);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate(){
    // this.$store.commit('setMenu', this.$router.options.routes[1].children);
    polyfills();

    this.$axios.interceptors.response.use((resp)=>{
      // console.log(111, resp.headers);
      return resp;
    }, (e) => {
      // console.log(22222, err, err.request, err.response, err.config);
      // this.$store.getters.authorized == 'authorized' - Нужно на всякий случай, если на логине или регистрации какой-то урл (не user) вернёт 403, то не надо показывать окно разлогирования, а показать ошибку
      // console.log(1111,e);
      if (e.response && e.response.status == 401 && this.$store.getters.authorized == 'authorized' && !/user\/info\/$/.test(e.request.responseURL)){

        if (this.$store.getters.loader.show) {
          this.$store.dispatch('setLoader');
          console.log('Лоадер закрылся при 401');
        }

        this.$store.commit('setSession');
      }

      else if(e.response && e.response.status == 423){
        if(e.response.data.errorMessage == 'Role block '){
          this.$store.commit('setAccessRole');
        }
        else if (this.$route.name !== 'Login'){
          this.$store.commit('setAccessUser');
        }
      }

      return Promise.reject(e);
    });


    // if (process.env.VUE_APP_MOCK === 'true')
    //   mock();

  },
}).$mount('#app')
