export default     {
    path: 'files',
    name: 'files',
    component: () => import(/* webpackChunkName: "files" */ '@/views/admin/FileManager/index.vue'),
    children:[
        {
            path: 'list',
            name: 'filegroup',
            component: () => import(/* webpackChunkName: "filegroup" */ '@/views/admin/FileManager/FileGroup.vue'),
        },
    ]
}
