import { getSystemInfo, getUserCertificates } from "crypto-pro"
export default {
    state: {
        certificates: [],
        pluginInfo: null,
    },
    mutations: {
        setCertificates(state, data = []) {
            state.certificates = data
        },
        setPluginInfo(state, data = []) {
            state.pluginInfo = data
        },
    },
    actions: {
        async getCertificates({ commit }) {
            try {
                let certificates = await getUserCertificates()

                commit('setCertificates', certificates)
            } catch (error) {
            }
        },
        async getPluginInfo({ commit }) {
            try {
                let pluginInfo = await getSystemInfo()

                commit('setPluginInfo', pluginInfo)
            } catch (error) {
            }
        },
    },
    getters: {
        certificates(state) {
            return state.certificates
        },
        pluginInfo(state) {
            return state.pluginInfo
        }
    }
}
