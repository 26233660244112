<template>
  <v-alert
    id="alert-error"
    type="error"
    dark
    icon="mdi-alert-circle"
    border="left"
    prominent
    :value="!!error.message"
    transition="fade-transition"
    dismissible
    dense
    @input="hide"
  >
    <v-expansion-panels class="min-h">
      <v-expansion-panel style="flex-basis: auto">
        <v-expansion-panel-header>{{ error.name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>Номер: {{ error.key }}</div>
          {{ error.message }}
          <div v-if="isDebug">
            <div v-if="error.fn">
              fn: {{ error.fn }}
            </div>
            <div v-if="error.uri">
              uri: {{ error.uri }}
            </div>
            <div v-if="Object.keys(error.params).length">
              params: {{ error.params }}
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-alert>
</template>
<script>
export default {
  name: 'Error',
  computed: {
    error() {
      return this.$store.getters.error
    },
    isDebug() {
      return process.env.VUE_APP_DEBUG === 'true'
    }
  },
  methods: {
    hide() {
      this.$store.commit('clearError')
    },
  },
}
</script>
<style lang="sass">
.v-alert__content
  z-index: 9999!important
.min-h
  min-width: 290px!important
#alert-error
  position: fixed
  min-width: 300px
  bottom: 20px
  right: 10px
  z-index: 999!important
.theme--dark.v-expansion-panels .v-expansion-panel
  background-color: rgba(0, 0, 0, 0) !important
</style>
