<template>
  <v-dialog
    :value="isBlockUser"
    persistent
    max-width="500"
  >
    <v-card
      class="white white--text login-block pt-5"
      color="navbar"
    >
      <h3 class="text-center text--white mb-3">
        Пользователь {{ user.username }} заблокирован, перезайдите под другой учетной записью, или обратитесь к Администратору
      </h3>
      <div class=" text-center w-100 d-block mb-3">
        <v-icon
          size="55"
          color="write"
        >
          mdi-account-clock-outline
        </v-icon>
      </div>
      <v-card-text class="signin-card-login">
        <v-row
          class="d-flex"
          style="padding-left: 0; padding-right: 16px;"
        >
          <v-btn
            style="width: 100%;"
            color="primary"
            class="ma-2"
            @click="logout"
          >
            Выход
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {mapGetters} from 'vuex'
import {defineComponent} from "vue"

export default defineComponent({
  name: "BlockUser",
  computed: {
    ...mapGetters({
      isBlockUser: 'isBlockUser',
      user: 'user',
    }),
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
        .then(()=> {
          this.$store.commit('setAccessUser', true)
          this.$router.push({name: 'Login'})
        })
    },
  }
})
</script>

<style scoped>

</style>
