import { axios } from '@/plugins/http-axios';
import { IAddressValue, IBase } from '@ifr/form-questionnaire/src/types/Elements';
import { Module } from 'vuex';
import { RootState } from '@/store/index';

import { App, IApplication, Okato, Okved } from '@/types/appTypes';
import { IUploadedDoc, IDocumentType, TClientDoc } from '@/types/docTypes';
import { sortQuestionnaire } from '@ifr/form-questionnaire/src/plugins/formUtilites';
import { isAppManual } from '@/plugins/utils';
import { getDocumentsForSigning, isSigningBank, isSigningCounterparties } from '@/utils';

// type TDoc = IReq|IDoc;
interface IAppState {
  app: App;
  clientDocs: TClientDoc[];
  // clientDocs: TDoc[];
  fieldDocs: IUploadedDoc[];
  fieldDocumentTypes: IDocumentType[];
  quest: IBase[];
  signedDocTypes: any[];
  bankSignatories: any[];
  participantSignatories: any[];
}

export default <Module<IAppState, RootState>>{
  state: {
    app: new App(),
    clientDocs: [],
    fieldDocs: [],
    fieldDocumentTypes: [],
    quest: [],
    signedDocTypes: [],
    bankSignatories: [],
    participantSignatories: [],
  },
  mutations: {
    setApp(state, data: App) {
      state.app = data;
      state.app.application.okato = new Okato(data.application.okato);
      state.app.application.okved = new Okved(data.application.okved);
    },
    setClientDocs(state, data: TClientDoc[]) {
      getDocumentsForSigning(data)
        .forEach(item  => {
          item.docs.forEach(doc => {
            //Должен ли подписывать КА(УЛ)
            if (isSigningCounterparties(item)){
              //Подписал ли он
              doc.isParticipantSigned = doc.doc.participantSignatory.isSigned === true;
            }
            else{
              doc.isParticipantSigned = true;
            }

            //Должен ли подписывать сотрудник Банка
            if (isSigningBank(item)){
              //Подписал ли он
              doc.isBankSigned = doc.doc.bankSignatory.isSigned === true;
            }
            else {
              doc.isBankSigned = true;
            }

            //Доступность кнопки-стрелки "Отправить на подписание"
            doc.isAvailableForSignature = true;
            //Активность этой кнопки
            doc.isSignFinished = doc.isParticipantSigned && doc.isBankSigned;
            doc.isDisabledButton = doc.doc.participantSignatory.isSigned === false || doc.doc.bankSignatory.isSigned === false;

          })
        });

      state.clientDocs = data;
    },
    setFieldDocs(state, data) {
      state.fieldDocs = data;
    },
    setFieldDocumentTypes(state, data) {
      state.fieldDocumentTypes = data;
    },
    setQuestionnaire(state, quest: IBase[]) {
      state.quest = quest;
    },
    setSignedDocTypes(state, data: any[] = []) {
      state.signedDocTypes = data;
    },
    setBankSignatories(state, data: any[] = []) {
      state.bankSignatories = data;
    },
    setParticipantSignatories(state, data: any[] = []) {
      state.participantSignatories = data;
    },
  },
  actions: {
    getParticipantSignatories(
      { getters, commit, dispatch, rootGetters },
      appId: string = getters.appId,
    ): Promise<any[]> {
      if (getters.listParticipantSignatory.length) return Promise.resolve(getters.listParticipantSignatory);

      const url = rootGetters.uri.participantSignatories(appId);
      return axios
        .get<any[]>(url)
        .then((resp) => {
          commit('setParticipantSignatories', resp.data);

          return getters.listParticipantSignatory;
        })
        .catch((e) => {
          e.uri = url;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getSignedDocTypes(
      { getters, commit, dispatch, rootGetters },
      appId: string = getters.appId,
    ): Promise<any[]> {
      if (getters.listSignedDocTypes.length) return Promise.resolve(getters.listSignedDocTypes);

      const url = rootGetters.uri.signedDocTypes(appId);
      return axios
        .get<any[]>(url)
        .then((resp) => {
          commit('setSignedDocTypes', resp.data);
          return getters.listSignedDocTypes;
        })
        .catch((e) => {
          e.uri = url;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getBankSignatories({ getters, commit, dispatch, rootGetters }): Promise<any[]> {
      if (getters.listBankSignatory.length) return Promise.resolve(getters.listBankSignatory);

      if (!getters.app.application.bankoffice?.id)
        return Promise.resolve([]);

      const url = rootGetters.uri.bankSignatoriesByOfficeId(getters.app.application.bankoffice.id);
      return axios
        .get<any[]>(url)
        .then((resp) => {
          commit('setBankSignatories', resp.data);
          return getters.listBankSignatory;
        })
        .catch((e) => {
          e.uri = url;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getQuestionnaire(
      { state, commit, dispatch, rootGetters },
      id: string = state.app.application.id,
    ): Promise<IBase[]> {
      return axios
        .get<IBase[]>(rootGetters.uri.questionnaire(id))
        .then((resp) => {
          const quest = sortQuestionnaire(resp.data);
          commit('setQuestionnaire', quest);
          return state.quest;
        })
        .catch((e) => {
          if (e.response && e.response.status == 409) {
            dispatch('addSuccess', { message: 'Невозможно сохранить анкету!', color: 'error' });
            return Promise.reject(e);
          } else {
            e.uri = rootGetters.uri.questionnaire(id);
            dispatch('setError', e);
            return Promise.reject(e);
          }
        });
    },
    getApp({ state, commit, dispatch, rootGetters }, id: string = state.app.application.id): Promise<void> {
      return axios
        .get<App>(rootGetters.uri.appIdNew(id))
        .then((resp) => {
          commit('setApp', resp.data);
          commit('setSignedDocTypes');
          commit('setBankSignatories');
          commit('setParticipantSignatories');
        })
        .catch((e) => {
          e.uri = rootGetters.uri.appIdNew(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getClientDocs({ state, commit, dispatch, rootGetters }, id: string = state.app.application.id) {
      return axios
        .get<TClientDoc[]>(rootGetters.uri.clientDocs(id))
        .then((resp) => {
          commit('setClientDocs', resp.data);
          return;
        })
        .catch((e) => {
          e.uri = rootGetters.uri.clientDocs(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getFieldDocs({ state, commit, dispatch, rootGetters }, id: string = state.app.application.id) {
      return axios
        .get(rootGetters.uri.fieldDocs(id))
        .then((resp) => {
          commit('setFieldDocs', resp.data);
          return;
        })
        .catch((e) => {
          e.uri = rootGetters.uri.fieldDocs(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getFieldDocumentTypes({ state, commit, dispatch, rootGetters }, id: string = state.app.application.id) {
      return axios
        .get(rootGetters.uri.fieldDocumentTypes(id))
        .then((resp) => {
          commit('setFieldDocumentTypes', resp.data);
          return;
        })
        .catch((e) => {
          e.uri = rootGetters.uri.fieldDocumentTypes(id);
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    listSignedDocTypes: (state): any[] => state.signedDocTypes,
    listBankSignatory: (state): any[] => state.bankSignatories,
    listParticipantSignatory: (state): any[] => state.participantSignatories,
    quest: (state): IBase[] => state.quest,
    app: (state): App => state.app,
    account: (state): any => state.app.actions.find((par) => par.type === 'OPEN_ACCOUNT'),
    accountWithoutIntegration: (state): any => state.app.actions.find((par) => par.type === 'OPEN_ACCOUNT_WITHOUT_INTEGRATION'),
    application: (state): IApplication => state.app.application,
    appRegAddress: (state): IAddressValue => ({
      text: state.app.application.regaddress,
      value: state.app.application.regAddressObject,
    }),
    appId: (state): string => state.app.application.id,
    clientPhone: (state): string =>
      state.app.application.client.phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3 $4 $5'),
    clientEmail: (state): string => state.app.application.client.email,
    clientDocs: (state): TClientDoc[] => state.clientDocs,
    documentsForSigning: (state): TClientDoc[] => getDocumentsForSigning(state.clientDocs),
    fieldDocs: (state): IUploadedDoc[] => state.fieldDocs,
    fieldDocumentTypes: (state): IDocumentType[] => state.fieldDocumentTypes,
    // fieldDocumentTypes: state => state.fieldDocumentTypes.map(item=>({text:item.name, value:item})),
    containsAction:
      (state) =>
        (action: string): boolean =>
          !!state.app.actions.find((item) => item.type == action),
    // actions: state => state.app.actions,
    // formatNumberInvoice: state => (number='') => number.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})$/, '$1 $2 $3 $4 $5'),
    // isIndividual: state => state.app.application.innEnum == 'INDIVIDUAL_ENTREPRENEURS',
    // isFinishStatus: state => state.app.finishStatus === true,
    appType: (state, getters): string => (getters.isLegalEntity ? 'LEGAL_ENTITY' : 'INDIVIDUAL'),
    isLegalEntity: (state): boolean => state.app.application.innEnum === 'LEGAL_ENTITY',
    isAppManual: (state, getters): boolean => isAppManual(getters.application.auto),
    isStartStatus: (state): boolean => state.app.application.actualStatus.info === 'START',
    isOkStop: (state): boolean => state.app.application.actualStatus.info === 'OK_STOP',
    isFailStop: (state): boolean => state.app.application.actualStatus.info === 'FAIL_STOP',
  },
};
