export default {
    MODAL_STRING_LENGTH : 30,

    APP_STATUS_FILL_APP_DATA : 1,
    APP_STATUS_SENDING_DOCS : 2,
    APP_STATUS_VERIFY_DOCS : 3,
    APP_STATUS_ADD_DOCS : 4,
    APP_STATUS_SIGN_APP : 5,
    APP_STATUS_SIGN_CONTRACT : 6,
    APP_STATUS_ACC_OPENED : 7,
    APP_STATUS_CUST_CANCEL : 8,
    APP_STATUS_BANK_DECLINE : 9,
    APP_STATUS_ACC_OPENING : 10,
    APP_STATUS_BANK_ANNUL : 11,

    APP_DOC_TYPE_REQ : 'REQ',
    APP_DOC_TYPE_DOC : 'DOC',

    APP_DOC_STATUS_VALID : 'VALID',
    APP_DOC_STATUS_NOT_VALID : 'NOT_VALID',
    APP_DOC_STATUS_PROCESS : 'PROCESS',
    APP_DOC_STATUS_FOR_CHECK : 'FOR_CHECK',

    OBJECTTYPE_INDIVIDUAL : 'INDIVIDUAL',

    CHECKER_TYPE_ADDRESS_MATCH_OTHER_ORGANIZATIONS: 'CHECKER_ADDRESS_MATCH_OTHER_ORGANIZATIONS',
    CHECKER_TYPE_CHECK_FNS_ACCOUNT_STOPPED: 'CHECK_FNS_ACCOUNT_STOPPED',
    CHECKER_TYPE_UNIFIED_PASSPORT_CHECK: 'UNIFIED_PASSPORT_CHECK',
    CHECKER_TYPE_COUNTERPARTIES_STATUS : 'COUNTERPARTIES_STATUS',
    CHECKER_TYPE_PASSPORT_CHECK : 'CHECK_INDIVIDUAL_PASSPORTS',
    CHECKER_TYPE_INDIVIDUAL_PASSPORTS_JSON : 'CHECK_INDIVIDUAL_PASSPORTS_JSON',
    CHECKER_AGE_CEO : 'AGE_CEO',
    CHECK_QUANTITY_LE_IND_BY_CEO : 'CHECK_QUANTITY_LE_IND_BY_CEO',
    DUPLICATE_APPLICATION : 'DUPLICATE_APPLICATION',

    CHECK_INTERNAL_API : 'CHECK_INTERNAL_API',
    EXTERNAL_VALIDATION : 'EXTERNAL_VALIDATION',
    CHECK_INTERNAL_API_SOAP : 'CHECK_INTERNAL_API_SOAP',
    EXTERNAL_VALIDATION_SOAP : 'EXTERNAL_VALIDATION_SOAP',
    FOUNDER_VALID_COUNT : 'FOUNDER_VALID_COUNT',


    CHECKER_STATUS_UNDEFINED : "STATE_UNDEFINED",
    CHECKER_STATUS_OK : "STATE_OK",
    CHECKER_STATUS_FAIL : "STATE_FAIL",
    CHECKER_STATUS_IN_PROGRESS : "STATE_IN_PROGRESS",
    CHECKER_STATUS_EXTERNAL_ERROR : "STATE_EXTERNAL_ERROR",

    CHECHER_SEVERITY_FAIL : "FAIL",
    CHECHER_SEVERITY_WARNING : "WARNING",

    FORMATE_DATE_FULL : 1,

    FILENAME_MAX_LENGTH : 50,


    ORG_INN_IN_CFT_BANK_BLACKLIST: 'ORG_INN_IN_CFT_BANK_BLACKLIST',


    FLAG_SELECT_PRODUCT_WHEN_OPENING : 'SELECT_PRODUCT_WHEN_OPENING',
    FLAG_HIDE_INVITED_MANAGER : 'HIDE_INVITED_MANAGER',
    FLAG_HIDE_COMMUNICATION_METHOD : 'HIDE_COMMUNICATION_METHOD',
    FLAG_HIDE_OFFICE : 'HIDE_OFFICE',
    FLAG_HIDE_QUESTIONNAIRE : 'HIDE_QUESTIONNAIRE',
    FLAG_LKB_CREATE_APPLICATION : 'LKB_CREATE_APPLICATION',
    FLAG_HIDE_PROMOCODE : 'HIDE_PROMOCODE',
    FLAG_SHOW_CLIENTS : 'SHOW_CLIENTS_IN_LKB',

    SECTION_CLIENTS : "CLIENTS",

    METAINFO_PROMOCODE : 'promoCode',

    DEFAULT_LINES_PER_PAGE : '15',

    DOC_TYPE_UNDEFINED :  0,
    DOC_TYPE_REQ :  -1,

    CHECK_TYPE_SYSTEM : "SYSTEM",
    CHECK_TYPE_MANUAL : "MANUAL",
    CHECK_PASSPORT_IN_SMAW: 'CHECK_PASSPORT_IN_SMAW',
    CHECK_PASSPORT_SMAW_4_JSON: 'CHECK_PASSPORT_SMAW_4_JSON',
    FILTER_ACT_LIST : 'actlistFilter',


    PRODUCT_TYPES : {
        ACCOUNT_OPENING: 1,
        GUARANTEES: 2
    },
   CHECK_KRONOS_API:'CHECK_KRONOS_API',


    DOCUMENT_SUB_TYPE_FORM_BANK : 'LKB_FORM_BANK',
}
