<template>
  <div class="mx-3 alert-success">
    <SuccessItem
      v-for="(item, i) in storeSuccess"
      :item="item"
      :index="i"
    />
  </div>
</template>

<script>
    import SuccessItem from "@/components/Success/SuccessItem"
    import {mapGetters} from "vuex"
    export default {
        name: "Success",
        components:{SuccessItem},
        computed:{
            ...mapGetters(['storeSuccess'])
        }
    }
</script>

<style scoped>

</style>