import {axios} from "@/plugins/http-axios";
import {Module} from "vuex";
import {RootState} from "@/store/index";
import {IOfficesOfRegion} from "@ifr/form-questionnaire/src/types/bankRegion";
import {IRole} from "@/store/user";
export interface ISysParameter{
    code: string;
    desc: string;
    value: string;
    resulttype: string;
};
interface ITakers{
    "id": string;// "cde5de04-5ecd-4a63-80c8-4ff07246cb9d",
    "fio": string; // "123 123 ",
    "roleName": string;// "ЮрУ"
};
export interface IStatusBank{
    "name": string; //"Аннулировано банком BANK_ANNUL",
    "count": number; //1515,
    "id": number; //11
};
interface ISigner{
    "id": string; //"cde5de04-5ecd-4a63-80c8-4ff07246cb9d",
    "fio": string; //"123 123 ",
    "roleName": string; //"ЮрУ"
};
interface ICommonBank{
    "id": number; //1,
    "name": string; //"АО \"Банк\"",
    "inn": string; //"7709345294",
    "kpp": string; //"770901001",
};
export interface IBaseOffice{
    "id": number;
    "name": string;
    "city": string;
    "shortaddress": string;
    "fulladdress": string;
};
export interface IBaseOffice2 extends IBaseOffice{
    "cftcode": string;
    "cftcalendar": string;
    "bik": string;
    "korr": string;
    "korrlocation": string;
    "branchcode": string;
    "code": string;
    "sortedItem": number;
    "bankid": number;
    "hide": boolean;
};
export interface IBankOffice extends IBaseOffice2{
    "regionid": number;
};
interface IBank2 extends ICommonBank{
    "okpo": string;
    "ogrn": string;
    regions: IBankRegion[];
};
export interface IBankRegion {
    id:number;
    name:string;
    hide:boolean;
    orderPosition:number;
    offices: IBankOffice[];
};
export interface IRegion {
    hide: boolean
    id: number
    name: string
    orderPosition: number
};
interface ISysSetting{
    "code": string; //"ENABLE_LKK_AGENT",
    "desc": string; //"Включить ЛКК для агентов",
    "value": boolean; //false,
    "affectedSystems": string[];//["Личный кабинет Банка", "Личный кабинет Клиента"]
};

interface IBankState{
    takers: ITakers[],
    statuses: IStatusBank[],
    signers: ISigner[],
    // offices: any[],
    officesList: IBankOffice[],
    regionsList: IRegion[],
    // officesListNotHide: any[],
    sysParameters: ISysParameter[],
    sysSettings: ISysSetting[],
    bankingRegions: IOfficesOfRegion[],
    roles: IRole[],
};

export default <Module<IBankState, RootState>>{
    state: {
        takers: [],
        statuses: [],
        signers: [],
        // offices: [],
        officesList: [],
        regionsList: [],
        // officesListNotHide: [],
        sysParameters: [],
        sysSettings: [],
        bankingRegions:[],
        roles: [],
    },
    mutations: {
        setSysSettings(state, data:ISysSetting[]){
            state.sysSettings = data;
        },
        setSysSettingsValue(state, {code='', value=false}={}) {
            const sysPar = state.sysSettings.find(par => par.code == code);
            if (sysPar)
                sysPar.value = value;
        },
        setSysParameters(state, data:ISysParameter[]) {
            state.sysParameters = data;
        },
        setTakers(state, data:ITakers[]) {
            state.takers = data;
        },
        setStatuses(state, statuses:IStatusBank[]) {
            state.statuses = statuses;
        },
        setSigners(state, data:ISigner[]) {
            state.signers = data;
        },
        setOffices(state, offices:IBankOffice[]) {
            state.officesList = offices;
        },
        setRegions(state, regions:IRegion[]) {

            state.regionsList = regions
                .filter(region=>![3,27,425].includes(region.id))
                .sort((a, b)=> a.orderPosition - b.orderPosition);
        },
        //todo Дублирование кода в лкк и лкб
        _setBankingRegions(state, regions:IBankRegion[]) {
            regions.sort((a, b)=>{ return a.orderPosition - b.orderPosition; });

            state.bankingRegions = regions.map<IOfficesOfRegion>(region=>{
                region.offices.sort((a, b)=>{ return a.sortedItem - b.sortedItem; });
                return {text: region.name, value:region.offices};
            });
        },
        setRoles(state, data:IRole[]) {
            state.roles = data;
        },
        /*
                setOfficesNotHide(state, data) {
                    state.officesListNotHide = data;
                }
        */
    },
    actions: {
        //todo Повторяющийся запрос в env
        async getSysParameters({state, commit, dispatch, rootGetters}):Promise<ISysParameter[]> {
            if (state.sysParameters.length)
                return Promise.resolve(state.sysParameters);
            return axios.get<ISysParameter[]>(rootGetters.uri.sysParameters)
                .then(resp => {
                    commit('setSysParameters', resp.data);
                    return resp.data;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.sysParameters;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        async getTakers({state, commit, dispatch, rootGetters}):Promise<void> {
            if (state.takers.length)
                return Promise.resolve();
            return axios.get<ITakers[]>(rootGetters.uri.usertakers)
                .then((resp) => {
                    commit('setTakers', resp.data);
                    return;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.usertakers;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        async getStatusCount({commit, dispatch, rootGetters}):Promise<void> {
            return axios.get<IStatusBank[]>(rootGetters.uri.applicationStatuscount)
                .then((resp) => {
                    commit('setStatuses', resp.data);
                    return;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.applicationStatuscount;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        async getSigners({state, commit, dispatch, rootGetters}):Promise<void> {
            if (state.signers.length)
                return Promise.resolve();
            return axios.get<ISigner[]>(rootGetters.uri.userSigner)
                .then((resp) => {
                    commit('setSigners', resp.data);
                    return;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.userSigner;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        //список всех офисов без исключения,
        async getOffices({getters, commit, dispatch, rootGetters}):Promise<IBankOffice[]> {
            if (getters.officesList.length)
                return Promise.resolve(getters.officesList);
            return axios.get<IBankOffice[]>(rootGetters.uri.office)
                .then((resp) => {
                    commit('setOffices', resp.data);
                    return Promise.resolve(getters.officesList);
                })
                .catch(e => {
                    e.uri = rootGetters.uri.office;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        async getRegions({commit, dispatch, rootGetters, getters}):Promise<IRegion[]>{
            if (getters.regionsList.length)
                return Promise.resolve(getters.regionsList);

            return axios.get<IRegion[]>(rootGetters.uri.region)
                .then(resp => {
                    commit('setRegions', resp.data);
                    return Promise.resolve(getters.regionsList);
                })
                .catch(e => {
                    e.uri = rootGetters.uri.region;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        //Список регионов с офисами, для анкеты
        async getBankingRegions({commit, dispatch, rootGetters, getters}):Promise<IOfficesOfRegion[]>{
            if (getters.isBankingRegions)
                return Promise.resolve(getters.bankingRegions);

            return axios.get<IBank2[]>(rootGetters.uri.bankOffices)
                .then(resp => {
                    commit('_setBankingRegions', resp.data[0].regions);
                    return getters.bankingRegions;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.bankOffices;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
        async getAllRoles({getters, rootGetters, dispatch, commit}): Promise<IRole[]>{
            if (getters.isRoles)
                return Promise.resolve(getters.roles);

            const url = rootGetters.uri.roles;
            return axios.get<IRole[]>(url)
                .then((resp) => {
                    commit('setRoles', resp.data);
                    return resp.data;
                })
                .catch(e => {
                    e.uri = url;
                    dispatch('setError', e);
                    return Promise.resolve(getters.roles);
                });
        },
        //todo Повторяющийся запрос в env
        async getSystemSetting({state, commit, dispatch, rootGetters}):Promise<void> {
            if (state.sysSettings.length)
                return Promise.resolve();
            return axios.get<ISysSetting[]>(rootGetters.uri.mainsysset)
                .then(resp => {
                    commit('setSysSettings', resp.data);
                    return;
                })
                .catch(e => {
                    e.uri = rootGetters.uri.mainsysset;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters: {
        isBankingRegions: (state):Boolean => !!state.bankingRegions.length,
        isRoles: (state):Boolean => !!state.roles.length,
        roles: (state):IRole[] => state.roles,
        listRoles: (state):any[] => state.roles.map(role => {
            return {
                text: role.name,
                value: role.id,
            }
        }),
        bankingRegions: (state):IOfficesOfRegion[] => state.bankingRegions,
        takers: (state):ITakers[] => state.takers,
        statusesList: (state):IStatusBank[] => state.statuses,
        signers: (state):ISigner[] => state.signers,
        // offices: (state) => state.offices,
        officesList: (state):IBankOffice[] => state.officesList,
        regionsList: (state):IRegion[] => state.regionsList,
        // officesListNotHide: (state) => state.officesListNotHide,
        sysParameters: (state):ISysParameter[] => state.sysParameters,
        sysSettings: (state):ISysSetting[] => state.sysSettings,
        sysSetting: (state, getters) => (key:string):boolean => {
            const o = getters.sysSettings.find((par:ISysSetting)=> par.code == key);
            return o ? o.value : false;
        },
    },
};
