import step2json from '@/store/step2json';
import { _getFields } from '@ifr/form-questionnaire/src/plugins/formUtilites';
import {
  IBase,
  IBaseField,
  IBaseSection,
  IEntityField,
  isBaseField,
  isBaseSection,
  isEntitySection,
  isField,
  isSection,
  TField,
} from '@ifr/form-questionnaire/src/types/Elements';
import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface IFieldDescription {
  type: string;
  name: string;
}
export type Journal = Map<string, IFieldDescription>;
export interface FormBuilderState {
  _journal: Journal; //only BaseFields
  json: IBase[];
}
interface PayloadSetField {
  data: IBaseField;
  i: number;
  j: number;
}

export interface IPayloadUpdateChildrenByParentId {
  parentId: string;
  callback: (item: IBaseField | IBaseSection | IEntityField) => void;
}

const storeModule = <Module<FormBuilderState, RootState>>{
  // namespaced: true,
  state: {
    _journal: new Map(),
    json: step2json.slice(),
  },
  mutations: {
    setJournal(state, field:TField){
      state._journal.set(field.id, {
        type: field.name,
        name: field.label,
      });
    },
    clearJournal(state) {
      state._journal.clear();
    },
    delJournal(state, id: string = '') {
      state._journal.delete(id);
    },
    setJson(state, quest: IBase[] = []) {
      state.json = quest;
    },
    //Редактирование json-представления базового поля baseField
    setField(state, { data, i = 0, j = 0 }: PayloadSetField) {
      let section: IBase = state.json[i];
      if (isSection(section)) {
        if (section.fields) {
          section.fields.splice(j, 1, data);
        } else if (isEntitySection(section) && section.blocks) {
          //participants, counterparties
          section.blocks[0].fields.splice(j, 1, data);
        }
      } else {
        state.json.splice(i, 1, data);
      }
    },
    //todo Я считаю, что для красоты этот метод можно отсюда изъять, а здесь оставить пустышку для обновления рендеринга, реактивности.
    //М.б. я ошибаюсь, и так компактнее
    updateChildrenByParentId(state, { parentId, callback }: IPayloadUpdateChildrenByParentId) {
      state.json.forEach((element) => {
        let children: (IBaseField | IBaseSection | IEntityField)[];

        children = <(IBaseField | IEntityField)[]>(
          _getFields(element).filter((field) => isField(field) && field.parent.id && field.parent.id == parentId)
        );

        if (isBaseSection(element) && element.parent && element.parent.id && element.parent.id == parentId)
          children.push(element);

        children.forEach(callback);
      });
    },
  },
  actions: {
/*
    deleteField({state, commit}, field: TField){
      if (field.name === 'signatory') {
        const fields = findFieldsByName(state.json, 'signatory');

        fields.forEach((field, i)=> {
          const oldId = field.id;
          const matches = field.id.match(/^(.+?)(?:_\d+)?$/) || [];
          const [,key] = matches;
          field.id = `${key}_${i+1}`;

          commit('delJournal', oldId);

          commit('setJournal', field);
        });
      }
    },
*/
    addJournal({state, commit}, field: IBaseField) {
      if (field.id && isBaseField(field) && field.name !== 'calendar') {
            commit('setJournal', field);
      }
    },
  },
  getters: {
    json: (state): IBase[] => state.json,
    journalId: (state): Journal => state._journal,
  },
};

export default storeModule;
