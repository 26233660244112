<template>
  <div>
    <v-dialog
      v-model="updateSystem"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        class="system-update"
        color="primary"
      >
        <v-row>
          <v-col cols="12">
            <p class="display-2 d-block white--text">
              Обновление системы! Подождите...
            </p>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              size="60"
              width="5"
              color="white"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import {mapGetters} from 'vuex'
import {defineComponent} from "vue"
export default defineComponent( {
  name: "UpdateSystem",
  computed: {
    ...mapGetters({
      updateSystem: 'updateSystem'
    }),
  },
})
</script>
<style scoped>
</style>
