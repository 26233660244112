import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import ru from 'vuetify/src/locale/ru.ts'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.blue.darken1,
                secondary: colors.red.darken4,
                accent: colors.blue.lighten4,
                save: colors.green.base,
                header: colors.blue.darken3,
                navbar: colors.blue.darken4,
                btn_def: colors.blue.darken2,

                background: "#eef2f4",
                headerColor: "#ddd",
                navbarColor: "#333",
                footerColor: "#333",

                checkColor: colors.orange.darken1,
                radioColor: colors.orange.darken1,
                selectColor: colors.orange.darken1,
                inputColor: colors.orange.darken1,

                applicationsColorManual: "#dae1e6",
                applicationsColorStatusOk: "#ccffe6",
                applicationsColorWarning: "#ffffcc",
                applicationsColorUndefined: "#f0f8ff",
                applicationsColorError: "#ff99bb",

                applicationsColorAuthorizedPersons: "#edf3fc",
                blackWhiteColors: "#ffffff",

                customerDocumentsForCheck: "#ffffcc",
                customerDocumentsValid: "#ccffe6",
                customerDocumentsNotValid: "#eca7bd",
                customerDocumentsProcess: "#b3ebf1",

                ApplicationStatusesColor: "#d3d3d3"

            },
            dark:{
                btn_def: colors.blue.darken1,
                applicationsColorManual: "#8c98a1",
                applicationsColorStatusOk: "#6da187",
                applicationsColorWarning: "#adad80",
                applicationsColorUndefined: "#b0b8bf",
                applicationsColorError: "#ad617b",

                applicationsColorAuthorizedPersons: "#b0b8bf",
                blackWhiteColors: "#000000",

                customerDocumentsForCheck: "#adad80",
                customerDocumentsValid: "#7fb399",
                customerDocumentsNotValid: "#ad7889",
                customerDocumentsProcess: "#7dadb3",

                ApplicationStatusesColor: "#9E9E9E"
            }

        },

    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: { ru },
        current: 'ru',
    },
})
