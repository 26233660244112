export default async function getApp({ context, nextMiddleware }){
    console.log('getApp')
    try {
        await context.store.dispatch('getApp', context.to.params.id)
        await context.store.dispatch('getClientDocs', context.to.params.id)
        await context.store.dispatch('getFieldDocumentTypes', context.to.params.id)
        await context.store.dispatch('getFieldDocs', context.to.params.id)
        await context.store.dispatch('getQuestionnaire', context.to.params.id)
    }
    catch(e){
        console.error(e)
        return context.next(false)
    }
    return nextMiddleware()
}
