import {Module} from "vuex";
interface ISoundState{
    sound:  Map<string, HTMLAudioElement>;
};
export default <Module<ISoundState, any>>{
    state:{
        sound: new Map(),
    },
    mutations:{
        addSound(state, name:string) {
            const audio:HTMLAudioElement = new Audio('/sound/' + name + '.mp3');
            audio.volume = 0.01;
            state.sound.set(name, audio);
        }
    },
    actions:{
        setSounds({state, commit}, names:string[]=[]):Promise<void>{
            names.forEach(name=>{
                if (!state.sound.has(name))
                    commit('addSound', name);
            });
            return Promise.resolve();
        },
        play({state}, name:string){
            const s = state.sound.get(name);
            if (s){
                s.currentTime = 0;
                s.play();
            }
        },
    },
    getters:{
        isSounds: (state):boolean => !!state.sound.size,
    },
};
