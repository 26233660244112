import getSysParameters from '@/router/middleware/getSysParameters'

export default {
  path: 'form-builder',
  name: 'formBuilder',
  component: () => import(/* webpackChunkName: "formBuilder" */ '@/views/admin/FormBuilder/index.vue'),
  children: [
    {
      path: 'create-form',
      name: 'createForm',
      component: () => import(/* webpackChunkName: "createForm" */ '@/views/admin/FormBuilder/createForm.vue'),
      meta: {
        middleware: [getSysParameters],
      },
    },
  ],
}
