import Vue from 'vue'
import Vuex from 'vuex'
import { axios } from "../plugins/http-axios";
import Uri, { uri } from "@/store/uri";
import error from "@/store/error";
import loader from "@/store/loader";
import system from "@/store/system";
import color from "@/store/color";
import i18n from "@/store/i18n";
import user from "@/store/user";
import bank from "@/store/bank";
import env from "@/store/env";
import app from "@/store/app";
import actions from "@/store/actions";
import paging from "@/store/paging";
import success from "@/store/success";
import questionnaire from "@/store/formBuilder";
import updateSystem from "@/store/updateSystem";
import sound from "@/store/sound";
import certificates from "@/store/certificates";

Vue.use(Vuex);
// import {FormBuilderState} from "@/store/formBuilder";

export interface RootState {
    uri: Uri;
    // json?: FormBuilderState;
};

const store = new Vuex.Store<RootState>({
    //     const store: StoreOptions<RootState> = {
    state: {
        uri: uri,
    },
    mutations: {},
    actions: {
        logout({ commit, dispatch, state }) {
            commit('setAuthorized');
            // dispatch('closeSocket');
            // commit('setApps');
            // commit('step3/setId');
            localStorage.clear();

            return axios.get(state.uri.logout)
                .then(() => {
                    return true;

                })
                .catch(e => {
                    e.uri = state.uri.logout;
                    dispatch('setError', e);
                    return Promise.reject(e);
                });
        },
    },
    getters: {
        uri: state => state.uri,
    },
    modules: {
        error,
        loader,
        system,
        color,
        i18n,
        user,
        bank,
        env,
        app,
        actions,
        paging,
        success,
        questionnaire,
        updateSystem,
        sound,
        certificates
    }
});


if (process.env.NODE_ENV !== 'production') {
    store.subscribeAction((action, state) => {
        console.warn('Action:', action.type, action.payload);
    });
    store.subscribe((mutation, state) => {
        console.warn('Mutation:', mutation.type, mutation.payload);
    });
}

// export default new Vuex.Store<RootState>(store);
export default store;
